import React from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';

import DetailNav from './DetailNav/DetailNav';

import './DetailHeader.scss';

const DetailHeader = ( {
  text,
  navBtns,
  navClass,
} ) => (
  <>
    <div className={classNames( 'detailHeader', {
      'detailHeader--hasSubtitle': text.subtitle,
    } )}
    >
      <h2 className="detailHeader__title">

        <span className="detailHeader__paneTitle">
          { text.title }
        </span>

        { text?.tags?.map( ( item ) => (
          <span
            key={item.type}
            className={`detailHeader__tag detailHeader__tag--${item.type} detailHeader__tag--paneTag`}
          >
            {item.text}
          </span>
        ) )}
      </h2>

      { text.subtitle && (
        <h3 className="detailHeader__subtitle">{text.subtitle}</h3>
      )}
    </div>

    <DetailNav
      navBtns={navBtns}
      navClass={navClass}
    />
  </>
);

DetailHeader.propTypes = {
  text: propTypes.shape( {
    title: propTypes.string,
    subtitle: propTypes.string,
  } ).isRequired,
  navBtns: propTypes.arrayOf(
    propTypes.shape( {
      text: propTypes.string,
      link: propTypes.string,
    } ),
  ),
  navClass: propTypes.string,
};

DetailHeader.defaultProps = {
  navBtns: [],
  navClass: '',
};

export default DetailHeader;
