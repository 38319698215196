import React from 'react';
import PropTypes from 'prop-types';

import MapFilterMenu from '../../MapPage/MapFilterMenu/MapFilterMenu';

import './PageFilters.scss';

const PageFilters = ( { hideByFieldName } ) => (
  <div className="pageFilters">
    <MapFilterMenu
      hideByFieldName={hideByFieldName}
    />
  </div>
);

PageFilters.defaultProps = {
  hideByFieldName: null,
};

PageFilters.propTypes = {
  hideByFieldName: PropTypes.string,
};

export default PageFilters;
