import React from 'react';

import './MapCompassRose.scss';

const MapCompassRose = () => (
  <div className="mapCompassRose">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 581.95282 746.780151"
      style={{ enableBackground: 'new 0 0 581.95282 746.780151' }}
      xmlSpace="preserve"
    >
      <g>
        <path d="M322.704468,172.214767l2.843109,15.63681c23.755768,3.180954,46.878937,9.492798,69.079468,18.882858
        c16.061676,6.793488,31.554352,15.202835,46.04776,24.994247c14.359497,9.701187,27.863068,20.842636,40.13559,33.115189
        c12.272491,12.272491,23.413971,25.776062,33.115082,40.13559c9.791504,14.493347,18.200867,29.986053,24.994385,46.047729
        c9.513977,22.493378,15.864624,45.934692,19.00293,70.020935l15.624695,2.84079
        C558.588135,292.18808,454.276093,187.598724,322.704468,172.214767z"
        />
        <path d="M259.248352,172.214767l-2.843079,15.63681c-23.755783,3.180954-46.878967,9.492798-69.079498,18.882858
        c-16.061676,6.793488-31.554352,15.202835-46.047745,24.994247c-14.35952,9.701187-27.863091,20.842636-40.13559,33.115189
        c-12.272507,12.272491-23.413994,25.776062-33.115112,40.13559c-9.791485,14.493347-18.200863,29.986053-24.994347,46.047729
        c-9.514,22.493378-15.864618,45.934692-19.00297,70.020935l-15.6247,2.84079
        C23.36471,292.18808,127.676743,187.598724,259.248352,172.214767z"
        />
        <path d="M322.704468,739.3927l2.843109-15.63678c23.755768-3.180969,46.878937-9.492798,69.079468-18.882874
        c16.061676-6.793457,31.554352-15.20282,46.04776-24.994263c14.359497-9.701172,27.863068-20.842651,40.13559-33.115173
        c12.272491-12.272522,23.413971-25.776062,33.115082-40.135559c9.791504-14.493408,18.200867-29.986084,24.994385-46.047791
        c9.513977-22.493347,15.864624-45.934692,19.00293-70.020905l15.624695-2.84079
        C558.588135,619.419373,454.276093,724.008728,322.704468,739.3927z"
        />
        <path d="M259.248352,739.3927l-2.843079-15.63678c-23.755783-3.180969-46.878967-9.492798-69.079498-18.882874
        c-16.061676-6.793457-31.554352-15.20282-46.047745-24.994263c-14.35952-9.701172-27.863091-20.842651-40.13559-33.115173
        s-23.413994-25.776062-33.115112-40.135559c-9.791485-14.493408-18.200863-29.986084-24.994347-46.047791
        c-9.514-22.493347-15.864618-45.934692-19.00297-70.020905l-15.6247-2.84079
        C23.36471,619.419373,127.676743,724.008728,259.248352,739.3927z"
        />
      </g>
      <path d="M258.345764,0h13.520874l31.683563,44.298618V0h14.095001v67.317429h-13.559174l-31.645264-44.161327v44.161327
      h-14.095001V0z"
      />
      <path d="M290.97641,99.729156l-2.205811,12.38073l0,0l-45.239883,253.924957l-137.548706-95.226562
      l95.044594,137.287659L0,455.803741l201.026596,47.707764l-95.044594,137.287689l137.548706-95.226562l47.445694,201.20752
      l47.445709-201.20752l137.548706,95.226562l-95.044586-137.287689l201.026581-47.707764l-201.026581-47.707794l95.044586-137.287659
      l-137.548706,95.226562L293.18222,112.109886l0,0L290.97641,99.729156z M100.536926,445.873718l153.735321-27.181
      l26.774231-153.328461v139.16452c-20.848358,4.029663-37.315033,20.496521-41.344635,41.34494H100.536926z M254.272247,492.914764
      l-153.735321-27.18103h139.164917c4.029602,20.84845,20.496277,37.315308,41.344635,41.345001v139.16449L254.272247,492.914764z
      M481.415894,465.733734l-153.735321,27.18103l-26.7742,153.328461v-139.16449
      c20.848328-4.029694,37.315002-20.496552,41.344604-41.345001H481.415894z M290.97641,488.515564
      c-18.037323,0-32.711823-14.674316-32.711823-32.711823s14.6745-32.711823,32.711823-32.711823
      s32.711823,14.674316,32.711823,32.711823S309.013733,488.515564,290.97641,488.515564z M327.680573,418.692719l153.735321,27.181
      H342.250977c-4.029602-20.848419-20.496277-37.315277-41.344604-41.34494v-139.16452L327.680573,418.692719z"
      />
    </svg>
  </div>
);

export default MapCompassRose;
