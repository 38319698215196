import React, { useContext } from 'react';

import MapContext from '../../../MapPage/MapContext';

import MapControlsZoomLessIcon from './MapControlsZoomLessIcon';

const MapControlsZoomLess = () => {
  const {
    activeZoom,
    setActiveZoom,
    mapData,
  } = useContext( MapContext );

  // Get and set zoom settings.
  const minZoom = mapData?.googleMapsConfig?.zooms?.min;

  let buttonClass = '';
  if ( activeZoom ) {
    if ( activeZoom <= minZoom ) {
      buttonClass = 'mapControl--disabled';
    }
  }

  const zoomLess = () => {
    const newZoom = activeZoom - 1;
    setActiveZoom( newZoom );
  };

  return minZoom && (
    <button
      type="button"
      className={`mapControl mapControls-zoom--less ${buttonClass}`}
      onClick={zoomLess}
    >
      <MapControlsZoomLessIcon />
    </button>
  );
};

export default MapControlsZoomLess;
