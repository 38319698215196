import React, {
  useEffect,
  useState,
} from 'react';
import propTypes from 'prop-types';

import { getNormalizedApiDataByUuid } from '../../utils/ApiHelpers';
import { residenceNormalizedData } from './ResidenceNormalizedData';
import { assembleResidenceNavBtnsData } from './ResidenceHelpers';

import FadingContainer from '../FadingContainer/FadingContainer';

import DetailDialog from '../Detail/DetailDialog';
import DetailTable from '../Detail/DetailTable';
import ResidenceStatusWidget from './ResidenceStatusWidget';
import FloorPlanDetailCore from '../FloorPlan/FloorPlanDetailCore';

const ResidenceDetail = ( {
  uuid,
  setModalNavBtns,
} ) => {
  const [ unitData, setUnitData ] = useState( null );
  const [ fetchUnitData, setFetchUnitData ] = useState( true );
  const [ detailDialogData, setDetailDialogData ] = useState( { status: null, message: null } );

  // Get unit data on load or if we get an updated dialog message (which was
  // triggered by change to the residence)
  useEffect( () => {
    const fetchData = async () => {
      const include = [
        'field_floor_plan',
        'field_floor_plan.field_2d_floor_plan',
        'field_floor_plan.field_3d_floor_plan',
        'field_floor_plan.field_finish_collection',
        'field_floor_plan.field_finish_collection.field_finish_option',
        'field_floor_plan.field_finish_collection.field_finish_option.field_finish_space',
        'field_floor_plan.field_finish_collection.field_finish_option.field_finish_palette',
        'field_floor_plan.field_finish_collection.field_finish_option.field_media_image',
        'field_floor_plan.field_photo_gallery.field_gallery_photos',
        'field_floor_plan.field_video',
        'field_floor_plan.field_virtual_tour',
        'field_floor_plan.field_virtual_tour.field_three_sixties',
        'field_building_grouping',
        'field_level',
        'field_status',
        'field_residents',
        'field_2d_floor_plan',
        'field_3d_floor_plan',
        'field_finish_collection',
        'field_finish_collection.field_finish_option',
        'field_finish_collection.field_finish_option.field_finish_space',
        'field_finish_collection.field_finish_option.field_finish_palette',
        'field_virtual_tour.field_three_sixties',
        'field_video',
        'field_view_video',
        'field_photo_gallery.field_gallery_photos',
        'field_gallery_photos',
        'field_view_gallery.field_gallery_photos',
      ];
      const request = {
        entityType: 'node',
        contentType: 'residence',
        uuid,
        params: {
          include: include.join( ',' ),
        },
      };
      const data = await getNormalizedApiDataByUuid( request, residenceNormalizedData );
      setUnitData( data );
      setFetchUnitData( false );
    };
    if ( uuid && fetchUnitData ) {
      fetchData();
    }
  }, [
    uuid,
    fetchUnitData,
  ] );

  // Set modal nav buttons.
  useEffect( () => {
    if ( unitData ) {
      const rawNavBtns = assembleResidenceNavBtnsData( unitData );
      setModalNavBtns( rawNavBtns );
    }
  }, [ unitData ] );

  // Create status widget.
  const residenceStatus = (
    <ResidenceStatusWidget
      residenceUuid={uuid}
      residenceTitle={unitData?.title}
      residentNamesString={unitData?.residents}
      residentUuids={unitData?.residentUuids}
      statusUuid={unitData?.statusUuid}
      residenceStatus={unitData?.status}
      setDetailDialogData={setDetailDialogData}
      setFetchUnitData={setFetchUnitData}
    />
  );

  // Assemble location and status data for table.
  const tableInfo = [];
  const location = unitData?.location;
  if ( location && location !== '' ) {
    tableInfo.push( {
      label: 'Location',
      data: location,
    } );
  }
  if ( residenceStatus ) {
    tableInfo.push( {
      label: 'Status',
      component: residenceStatus,
    } );
  }

  return unitData && (
    <FadingContainer>
      <div>

        <DetailDialog
          detailDialogData={detailDialogData}
        />

        <DetailTable
          id="info-scroll"
          tableInfo={tableInfo}
          tableDescription={unitData.description}
          className="detailSection"
        />

        <DetailTable
          tableInfo={unitData.roomFees}
          className="detailSection detailSection__displayFees"
        />

        <FloorPlanDetailCore
          title={unitData.title}
          roomFees={unitData.roomFees}
          floorplan2d={unitData.twoDFloorPlan?.modal}
          floorplan3d={unitData.threeDFloorPlan?.modal}
          downloadablePdf={unitData.downloadablePdf}
          description={unitData.description}
          roomData={unitData.roomsDims}
          view={unitData.view}
          photoGalleryImages={unitData.photoGallery?.images}
          video={unitData.video}
          threeSixties={unitData.virtualTour?.threeSixties}
          furnitureConfiguratorUrl={unitData.furnitureConfiguratorUrl}
          finishCollection={unitData.finishCollection}
        />

      </div>
    </FadingContainer>
  );
};

ResidenceDetail.propTypes = {
  uuid: propTypes.string,
  setModalNavBtns: propTypes.func,
};

ResidenceDetail.defaultProps = {
  uuid: null,
  setModalNavBtns: null,
};

export default ResidenceDetail;
