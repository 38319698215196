import React, {
  useEffect,
  useState,
} from 'react';
import propTypes from 'prop-types';

import { getNormalizedApiDataByUuid } from '../../utils/ApiHelpers';
import { assemblePlaceDetailNavBtnsData } from './PlaceHelpers';
import PlaceDetailNormalizeData from './PlaceDetailNormalizeData';

import FadingContainer from '../FadingContainer/FadingContainer';

import DetailHeader from '../Detail/DetailHeader';
import PhotoGalleryDetail from '../PhotoGallery/PhotoGalleryDetail/PhotoGalleryDetail';
import VideoDetail from '../Video/VideoDetail';
import VirtualTourDetail from '../VirtualTour/VirtualTourDetail';

import '../Detail/Detail.scss';

const PlaceDetail = ( {
  uuid,
  data,
  setModalNavBtns,
  hasInternalHeader,
  navBtns,
  navClass,
} ) => {
  const [ placeData, setPlaceData ] = useState( null );
  const [ detailHeaderNavBtns, setDetailHeaderNavBtns ] = useState( [] );
  const [ detailHeaderText, setDetailHeaderText ] = useState( {} );
  const [ photoGalleryImages, setPhotoGalleryImages ] = useState( null );

  // If we already have place data, set it.
  useEffect( () => {
    if ( data ) {
      setPlaceData( data );
    }
  }, [ data ] );

  // Get place data.
  useEffect( () => {
    if ( uuid ) {
      const fetchData = async () => {
        const include = [
          'field_cover_photo',
          'field_virtual_tour.field_three_sixties',
          'field_video',
          'field_photo_gallery.field_gallery_photos',
        ];
        const request = {
          entityType: 'node',
          contentType: 'place',
          uuid,
          params: {
            include: include.join( ',' ),
          },
        };
        const rawData = await getNormalizedApiDataByUuid(
          request,
          PlaceDetailNormalizeData,
        );
        setPlaceData( rawData );
      };
      fetchData();
    }
  }, [ uuid ] );

  // Set navbtns.
  useEffect( () => {
    if ( placeData && setModalNavBtns ) {
      const rawNavBtns = assemblePlaceDetailNavBtnsData( placeData );
      setModalNavBtns( rawNavBtns );
    }
  }, [ placeData ] );

  // Assemble header data if needed.
  const title = placeData?.title;
  useEffect( () => {
    if ( hasInternalHeader ) {
      // Get existing nav passed in or create new nav.
      const rawNavBtns = assemblePlaceDetailNavBtnsData( placeData );
      setDetailHeaderNavBtns( rawNavBtns );

      // Assemble header text.
      const rawHeaderText = {
        title,
        subtitle: null,
      };
      setDetailHeaderText( rawHeaderText );
    }
  }, [
    title,
    hasInternalHeader,
  ] );

  // Process photo gallery images or cover photo fallback if set.
  useEffect( () => {
    if ( placeData ) {
      let images = null;
      if ( placeData.photoGalleryImages?.length > 0 ) {
        images = placeData.photoGalleryImages;
      } else if (
        placeData.coverImage
        && ( placeData.threeSixties?.length === 0 )
        && !placeData.video
      ) {
        // If there's no vt, video, or gallery, and we want something to appear
        // make the cover photo a "gallery".
        images = [ {
          uuid: placeData.coverImage.id,
          caption: null,
          image: placeData.coverImage,
        } ];
      }
      setPhotoGalleryImages( images );
    }
  }, [ placeData ] );

  return placeData && (
    <FadingContainer>
      <div>

        { hasInternalHeader && (
          <DetailHeader
            text={detailHeaderText}
            navBtns={detailHeaderNavBtns}
            navClass={navClass}
          />
        )}

        { placeData?.description && (
          <div id="about-scroll" className="detailSection detailTable">
            <p className="detailTable-description">
              { placeData.description }
            </p>
          </div>
        )}

        <PhotoGalleryDetail
          id="slideshow-scroll"
          uuid={null}
          images={photoGalleryImages}
          galleryClass="slideshow"
          className="detailSection detailSection--gallery"
          imageClass="slideshowPhoto"
        />

        <VideoDetail
          hasId
          videoId={placeData.video?.serviceVideoId}
          service={placeData.video?.service}
          autoplay={false}
          className="detailSection"
        />

        <VirtualTourDetail
          id="tour-scroll"
          uuid={null}
          threeSixtiesData={placeData.threeSixties}
          className="detailSection detailSection--virtualTour"
        />

      </div>
    </FadingContainer>
  );
};

PlaceDetail.propTypes = {
  uuid: propTypes.string,
  data: propTypes.shape( {
    uuid: propTypes.string,
    title: propTypes.string,
    description: propTypes.string,
    coverImage: propTypes.string,
  } ),
  setModalTitle: propTypes.func,
  setModalNavBtns: propTypes.func,
  hasInternalHeader: propTypes.bool,
  navBtns: propTypes.arrayOf(
    propTypes.shape( {
      section: propTypes.string,
      text: propTypes.string,
    } ),
  ),
  navClass: propTypes.string,
};

PlaceDetail.defaultProps = {
  uuid: null,
  data: {},
  setModalNavBtns: null,
  hasInternalHeader: false,
  navBtns: [],
  navClass: '',
};

export default PlaceDetail;
