import React, { useEffect, useContext, useState } from 'react';

import MapContext from '../../../MapPage/MapContext';
import MapControlsZoomMore from './MapControlsZoomMore';
import MapControlsZoomLess from './MapControlsZoomLess';
import MapControlsZoomReset from './MapControlsZoomReset';

const MapControlsZoom = ( {
  siteSeeGuideId,
  setSiteSeeGuideId,
  siteSeeGuideMode,
} ) => {
  return (
    <>
      <MapControlsZoomMore
        siteSeeGuideId={siteSeeGuideId}
        setSiteSeeGuideId={setSiteSeeGuideId}
        siteSeeGuideMode={siteSeeGuideMode}
      />
      <MapControlsZoomLess />
      <MapControlsZoomReset />
    </>
  );
};

export default MapControlsZoom;
