import React, { useContext, useEffect, useState } from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';

import ConfigContext from '../../utils/ConfigContext/ConfigContext';
import { assembleFloorPlanNavBtnsData, createBedBathSqFtPricingTableData } from './FloorPlanHelpers';

import FadingContainer from '../FadingContainer/FadingContainer';

import DetailTableInfo from '../Detail/DetailTable';
import DetailHeader from '../Detail/DetailHeader';
import FloorPlanDetailCore from './FloorPlanDetailCore';

import '../Detail/Detail.scss';

const FloorPlanDetail = ( {
  data,
  hasInternalHeader,
  navBtns,
  navClass,
} ) => {
  const [ detailHeaderNavBtns, setDetailHeaderNavBtns ] = useState( [] );
  const [ detailHeaderText, setDetailHeaderText ] = useState( {} );
  const [ tableInfo, setTableInfo ] = useState( [] );
  const [ inventory, setInventory ] = useState( 'Sold Out' );

  const {
    config,
    showPricing,
  } = useContext( ConfigContext );
  const { isPIB } = config;

  const {
    remainingInventory,
  } = data;

  // Set inventory phrase.
  useEffect( () => {
    if ( remainingInventory && remainingInventory > 0 && remainingInventory <= 3 ) {
      setInventory( `${remainingInventory} Left` );
    }
  }, [ remainingInventory ] );

  // PIB needs to show beds/bath, YT does not.
  useEffect( () => {
    const rawTableInfo = createBedBathSqFtPricingTableData( data, showPricing, isPIB );
    setTableInfo( rawTableInfo );
  }, [
    data,
    showPricing,
    isPIB,
  ] );

  // Assemble header data if needed.
  useEffect( () => {
    if ( hasInternalHeader ) {
      // Get existing nav passed in or create new nav.
      const rawNavBtns = navBtns ?? assembleFloorPlanNavBtnsData( data );
      setDetailHeaderNavBtns( rawNavBtns );

      // Assemble header text.
      const rawHeaderText = {
        title: data?.title,
        tags: [],
        subtitle: `
          ${data?.levelsOfCareName || ''}
          ${data?.levelsOfCareName && data?.floorPlanCategoryName ? ' ' : ''}
          ${data?.floorPlanCategoryName || ''}
        `,
      };

      // Add inventory tag.
      if ( remainingInventory && remainingInventory <= 3 ) {
        rawHeaderText.tags.push(
          {
            type: 'soldOut',
            text: inventory,
          },
        );
      }

      setDetailHeaderText( rawHeaderText );
    }
  }, [
    data, hasInternalHeader, inventory,
  ] );

  return data && (
    <FadingContainer>
      <div>

        { hasInternalHeader && (
          <DetailHeader
            text={detailHeaderText}
            navBtns={detailHeaderNavBtns}
            navClass={navClass}
          />
        )}

        <DetailTableInfo
          id="info-scroll"
          tableInfo={tableInfo}
          tableDescription={data.description}
          className={classNames( 'detailSection', {
            detailTable__displayFees: !isPIB,
          } )}
        />

        <FloorPlanDetailCore
          title={data.title}
          roomFees={data.roomFees}
          floorplan2d={data.floorplan2d?.modal}
          floorplan3d={data.floorplan3d?.modal}
          downloadablePdf={data.downloadablePdf}
          description={data.description}
          roomData={data.roomData}
          view={null}
          photoGalleryImages={data.photoGalleryImages}
          video={data.video}
          threeSixties={data.threeSixties}
          furnitureConfiguratorUrl={data.furnitureConfiguratorUrl}
          finishCollection={data.finishCollection}
        />

      </div>
    </FadingContainer>
  );
};

FloorPlanDetail.propTypes = {
  data: propTypes.shape( {
    title: propTypes.string,
    levelsOfCareName: propTypes.string,
    floorPlanCategoryName: propTypes.string,
    roomFees: propTypes.arrayOf(
      propTypes.shape( {
        label: propTypes.string,
        data: propTypes.string,
        note: propTypes.string,
      } ),
    ),
    description: propTypes.string,
    floorplan2d: propTypes.shape( {
      modal: propTypes.string,
    } ),
    floorplan3d: propTypes.shape( {
      modal: propTypes.string,
    } ),
    roomData: propTypes.arrayOf(
      propTypes.shape( {
        label: propTypes.string,
      } ),
    ),
    threeSixties: propTypes.arrayOf(
      propTypes.shape( {
        id: propTypes.string,
        image: propTypes.string,
      } ),
    ),
    furnitureConfiguratorUrl: propTypes.string,
    video: propTypes.shape( {
      videoId: propTypes.string,
      service: propTypes.string,
    } ),
    photoGalleryImages: propTypes.arrayOf(
      propTypes.shape( {
        id: propTypes.string,
        image: propTypes.shape( {
          modal: propTypes.string,
        } ),
        caption: propTypes.string,
      } ),
    ),
    finishCollection: propTypes.shape( {
      id: propTypes.string,
      title: propTypes.string,
      options: propTypes.arrayOf(
        propTypes.shape( {
          title: propTypes.string,
          fields: propTypes.arrayOf(
            propTypes.shape( {
              label: propTypes.string,
              data: propTypes.string,
            } ),
          ),
        } ),
      ),
    } ),
    downloadablePdf: propTypes.string,
  } ),
  hasInternalHeader: propTypes.bool,
  navBtns: propTypes.arrayOf(
    propTypes.shape( {
      text: propTypes.string,
      link: propTypes.string,
    } ),
  ),
  navClass: propTypes.string,
};

FloorPlanDetail.defaultProps = {
  data: {},
  hasInternalHeader: false,
  navBtns: [],
  navClass: '',
};

export default FloorPlanDetail;
