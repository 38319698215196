import { processPriceFieldWithOptionalRange } from '../../utils/common';
import { processUnserializedThreeSixties } from '../VirtualTour/VirtualTourDetailNormalizeData';
import { finishCollectionNormalizeData } from '../FinishCollection/FinishCollectionNormalizeData';

const JSONAPIDeserializer = require( 'jsonapi-serializer' ).Deserializer;

/**
 * Normalize function for Residences.
 */
// eslint-disable-next-line import/prefer-default-export
export const residenceNormalizedData = async ( responseData ) => {
  const node = {};

  if ( !responseData ) {
    return node;
  }

  const drupalData = responseData.data;
  const drupalIncluded = responseData.included;

  const options = {
    keyForAttribute: 'camelCase',
  };

  const deserializedData = await new JSONAPIDeserializer( options ).deserialize( responseData );

  // change included array into object with id as property name
  // so we can retrieve them by their id later
  const drupalIncludedById = {};
  if ( drupalIncluded && drupalIncluded.length ) {
    drupalIncluded.forEach( ( item ) => {
      const { id } = item;
      drupalIncludedById[id] = item;
    } );
  }

  if ( drupalData ) {
    // title
    node.title = deserializedData.title;

    // fp data
    let fpData = {};
    const fpFieldId = drupalData?.relationships.field_floor_plan.data.id;
    if ( fpFieldId ) {
      fpData = drupalIncludedById[fpFieldId];
    }

    const deserializedFpData = deserializedData?.fieldFloorPlan;

    // location
    const locationArray = [];
    const buildingGroupingName = deserializedData?.fieldBuildingGrouping?.name;
    if ( buildingGroupingName ) {
      locationArray.push( buildingGroupingName );
    }

    const levelName = deserializedData?.fieldLevel?.name;
    if ( levelName ) {
      locationArray.push( levelName );
    }

    node.location = locationArray.join( ', ' );

    // Status - we ask for it when it's a request.
    const statusName = deserializedData?.fieldStatus?.name;
    if ( statusName ) {
      node.status = statusName;
      node.statusUuid = deserializedData?.fieldStatus?.id;
    }
    else {
      // If its not a request, just a response, we have to dig into the included
      // data get the status.
      node.statusUuid = drupalData?.relationships?.field_status?.data?.id;
    }

    // residents
    // @TODO - use deserialized data instead
    // @TODO - use existing common.js joinNames function to join names
    // @TODO - use const where appropriate
    const residentsArray = [];
    const residentUuidsArray = [];
    const residentsIdArray = drupalData?.relationships.field_residents.data;
    if ( residentsIdArray.length > 0 ) {
      residentsIdArray.forEach( ( item, index ) => {
        const residentData = drupalIncludedById[item.id];
        const residentName = residentData?.attributes.title;
        residentsArray.push( residentName );
        residentUuidsArray.push( item.id );
      } );
    }
    node.residents = residentsArray.join( ' & ' );
    node.residentUuids = residentUuidsArray;

    // pricing - unit overrides fp
    // @TODO - use deserialized data instead
    // @TODO - use const where appropriate
    const roomFees = [];
    const activeFieldEntranceFee1 = drupalData.attributes?.field_entrance_fee1 ? drupalData.attributes?.field_entrance_fee1 : fpData?.attributes?.field_entrance_fee1;
    const activeFieldEntranceFee2 = drupalData.attributes?.field_entrance_fee2 ? drupalData.attributes?.field_entrance_fee2 : fpData?.attributes?.field_entrance_fee2;
    const activeFieldMonthlyFee1 = drupalData.attributes?.field_monthly_fee1 ? drupalData.attributes?.field_monthly_fee1 : fpData?.attributes?.field_monthly_fee1;
    const activeFieldMonthlyFee2 = drupalData.attributes?.field_monthly_fee2 ? drupalData.attributes?.field_monthly_fee2 : fpData?.attributes?.field_monthly_fee2;
    if ( activeFieldEntranceFee1 || activeFieldEntranceFee2 ) {
      const dataString = activeFieldEntranceFee1 ? processPriceFieldWithOptionalRange( activeFieldEntranceFee1, true ) : null;
      const rawNoteString = activeFieldEntranceFee2 ? processPriceFieldWithOptionalRange( activeFieldEntranceFee2, false ) : null;
      const noteString = rawNoteString ? `Person Two: ${rawNoteString}` : null;
      const roomFee = {
        label: 'Entrance Fee',
        data: dataString,
        note: noteString,
      };
      roomFees.push( roomFee );
    }
    if ( activeFieldMonthlyFee1 || activeFieldMonthlyFee2 ) {
      const dataString = activeFieldMonthlyFee1 ? processPriceFieldWithOptionalRange( activeFieldMonthlyFee1, true ) : null;
      const rawNoteString = activeFieldMonthlyFee2 ? processPriceFieldWithOptionalRange( activeFieldMonthlyFee2, false ) : null;
      const noteString = rawNoteString ? `Person Two: ${rawNoteString}` : null;
      const roomFee = {
        label: 'Monthly Fee',
        data: dataString,
        note: noteString,
      };
      roomFees.push( roomFee );
    }
    node.roomFees = roomFees;

    // description - unit overrides fp
    const unitDescription = deserializedData?.fieldDescription;
    const fpDescription = deserializedFpData?.fieldDescription;
    const description = unitDescription && unitDescription !== '' ? unitDescription : fpDescription;
    node.description = description;

    // 2d floor plan
    // @TODO - use deserialized data instead
    let unit2dFloorPlan = null;
    const unit2dFpFieldData = drupalData?.relationships.field_2d_floor_plan.data;
    if ( unit2dFpFieldData ) {
      unit2dFloorPlan = {
        modal: unit2dFpFieldData.meta.imageDerivatives?.links?.modal?.href,
      };
    }

    let fp2dFloorPlan = null;
    const fp2dFpFieldData = fpData?.relationships.field_2d_floor_plan.data;
    if ( fp2dFpFieldData ) {
      fp2dFloorPlan = {
        modal: fp2dFpFieldData?.meta?.imageDerivatives?.links?.modal?.href,
      };
    }
    const twoDFloorPlan = unit2dFloorPlan || fp2dFloorPlan;
    node.twoDFloorPlan = twoDFloorPlan;

    // 3d floorplan
    // @TODO - use deserialized data instead
    let unit3dFloorPlan = null;
    const unit3dFpFieldData = drupalData?.relationships.field_3d_floor_plan.data;
    if ( unit3dFpFieldData ) {
      unit3dFloorPlan = {
        modal: unit3dFpFieldData?.meta?.imageDerivatives?.links?.modal?.href,
      };
    }

    let fp3dFloorPlan = null;
    const fp3dFpFieldData = fpData?.relationships.field_3d_floor_plan.data;
    if ( fp3dFpFieldData ) {
      fp3dFloorPlan = {
        modal: fp3dFpFieldData?.meta?.imageDerivatives?.links?.modal?.href,
      };
    }
    const threeDFloorPlan = unit3dFloorPlan || fp3dFloorPlan;
    node.threeDFloorPlan = threeDFloorPlan;

    // room dimensions - unit overrides fp if any values present
    // @TODO - use deserialized data instead
    const roomsDims = [];
    const activeRoomDimsField = drupalData?.attributes?.field_rooms.length > 0 ? drupalData.attributes?.field_rooms : fpData?.attributes?.field_rooms;
    activeRoomDimsField?.forEach( ( room ) => {
      const roomDims = {
        label: room.name,
        data: room.dimensions,
      };
      roomsDims.push( roomDims );
    } );
    node.roomsDims = roomsDims;

    // photo gallery - unit images, then unit gallery
    // if unit gallery AND fp gallery, unit gallery overrides
    // @TODO - use deserialized data instead
    const photoGalleryImages = [];

    const unitImagesField = drupalData?.relationships.field_gallery_photos.data;
    unitImagesField.forEach( ( item, index ) => {
      const image = {
        id: item.id,
        caption: item.meta.alt,
        image: {
          modal: item?.meta?.imageDerivatives?.links?.modal?.href,
        },
      };
      photoGalleryImages.push( image );
    } );

    const photoGalleryField = drupalData?.relationships.field_photo_gallery;
    if ( photoGalleryField.data ) {
      const photoGalleryFieldData = photoGalleryField.data;
      if ( photoGalleryFieldData.id ) {
        const photoGalleryId = photoGalleryFieldData.id;
        if ( photoGalleryId ) {
          const photoGalleryData = drupalIncludedById[photoGalleryId];
          const imagesData = photoGalleryData?.relationships.field_gallery_photos.data;
          imagesData.forEach( ( item, index ) => {
            const image = {
              id: item.id,
              caption: item.meta.alt,
              image: {
                modal: item.meta?.imageDerivatives?.links?.modal?.href,
              },
            };
            photoGalleryImages.push( image );
          } );
        }
      }
    } else {
      const fpPhotoGalleryField = fpData?.relationships.field_photo_gallery;
      if ( fpPhotoGalleryField?.data ) {
        const fpPhotoGalleryFieldData = fpPhotoGalleryField.data;
        if ( fpPhotoGalleryFieldData.id ) {
          const fpPhotoGalleryId = fpPhotoGalleryFieldData.id;
          if ( fpPhotoGalleryId ) {
            const fpPhotoGalleryData = drupalIncludedById[fpPhotoGalleryId];
            const fpImagesData = fpPhotoGalleryData?.relationships.field_gallery_photos.data;
            fpImagesData.forEach( ( item, index ) => {
              const image = {
                id: item.id,
                caption: item.meta.alt,
                image: {
                  modal: item.meta?.imageDerivatives?.links?.modal?.href,
                },
              };
              photoGalleryImages.push( image );
            } );
          }
        }
      }
    }

    if ( photoGalleryImages.length > 0 ) {
      node.photoGallery = {};
      node.photoGallery.images = photoGalleryImages;
    }

    // virtual tour - unit overrides fp
    // @TODO - use deserialized data instead
    let virtualTourData = null;
    const unitVirtualTourData = drupalData?.relationships.field_virtual_tour.data;
    const fpVirtualTourData = fpData?.relationships.field_virtual_tour.data;
    if ( unitVirtualTourData ) {
      virtualTourData = unitVirtualTourData;
    } else if ( fpVirtualTourData ) {
      virtualTourData = fpVirtualTourData;
    }
    if ( virtualTourData ) {
      const virtualTourId = virtualTourData.id;
      const virtualTourNode = drupalIncludedById[virtualTourId];
      const threeSixtiesData = virtualTourNode?.relationships.field_three_sixties.data;
      const threeSixties = processUnserializedThreeSixties( threeSixtiesData, drupalIncludedById );
      node.virtualTour = {};
      node.virtualTour.threeSixties = threeSixties;
    }

    // finish collection - unit overrides fp.
    const unitFinishCollection = finishCollectionNormalizeData(
      deserializedData?.fieldFinishCollection?.fieldFinishOption,
      drupalIncluded,
    );
    const fpFinishCollection = finishCollectionNormalizeData(
      deserializedFpData?.fieldFinishCollection?.fieldFinishOption,
      drupalIncluded,
    );
    const finishCollecton = unitFinishCollection && Object.keys( unitFinishCollection ).length
      ? unitFinishCollection
      : fpFinishCollection;
    node.finishCollection = finishCollecton;

    // furniture configurator - unit url overrides fp url
    const unitFurnitureConfiguratorUrl = deserializedData?.fieldFurnitureConfiguratorUrl?.uri;
    const fpFurnitureConfiguratorUrl = deserializedFpData?.fieldFurnitureConfiguratorUrl?.uri;
    const furnitureConfiguratorUrl = unitFurnitureConfiguratorUrl || fpFurnitureConfiguratorUrl;
    node.furnitureConfiguratorUrl = furnitureConfiguratorUrl;

    // VIDEO - Unit unique overrides unit existing which overrides floor plan existing.
    let unitVideoId = null;
    let unitVideoService = null;
    const unitNewVideoId = deserializedData?.fieldVideoId;
    const unitNewVideoService = deserializedData?.fieldVideoService;

    if ( unitNewVideoId && unitNewVideoService ) {
      unitVideoId = deserializedData?.fieldVideoId;
      unitVideoService = deserializedData?.fieldVideoService;
    }
    else {
      const unitExistingVideoId = deserializedData?.fieldVideo?.fieldVideoId;
      const unitExistingVideoService = deserializedData?.fieldVideo?.fieldVideoService;

      if ( unitExistingVideoId && unitExistingVideoService ) {
        unitVideoId = unitExistingVideoId;
        unitVideoService = unitExistingVideoService;
      }
      else {
        const fpVideoId = deserializedFpData?.fieldVideo?.fieldVideoId;
        const fpVideoService = deserializedFpData?.fieldVideo?.fieldVideoService;

        if ( fpVideoId && fpVideoService) {
          unitVideoId = fpVideoId;
          unitVideoService = fpVideoService;
        }
      }
    }

    if ( unitVideoId && unitVideoService ) {
      node.video = {
        service: unitVideoService,
        serviceVideoId: unitVideoId,
      };
    }

    // VIEW
    const view = [];

    // view - new video
    const unitViewNewVideoId = deserializedData?.fieldViewVideoId;
    const unitViewNewVideoService = deserializedData?.fieldViewService;
    if ( unitViewNewVideoId && unitViewNewVideoService ) {
      const viewItem = {
        type: 'Video',
        data: {
          service: unitViewNewVideoService,
          serviceVideoId: unitViewNewVideoId,
        },
      };
      view.push( viewItem );
    }

    // view - existing video
    const unitViewExistingVideoId = deserializedData?.fieldViewVideo?.fieldVideoId;
    const unitViewExistingVideoService = deserializedData?.fieldViewVideo?.fieldVideoService;
    if ( unitViewExistingVideoId && unitViewExistingVideoService ) {
      const viewItem = {
        type: 'Video',
        data: {
          service: unitViewExistingVideoService,
          serviceVideoId: unitViewExistingVideoId,
        },
      };
      view.push( viewItem );
    }

    // view - unit view images, then unit gallery
    // @TODO - use deserialized data instead
    const unitViewPhotoGalleryImages = [];

    const unitViewImagesFieldData = drupalData?.relationships?.field_view_photos?.data;
    if ( unitViewImagesFieldData ) {
      unitViewImagesFieldData.forEach( ( item ) => {
        const image = {
          id: item?.id,
          caption: item?.meta?.alt,
          image: {
            modal: item?.meta?.imageDerivatives?.links?.modal?.href,
          },
        };
        unitViewPhotoGalleryImages.push( image );
      } );
    }

    const unitViewGalleryId = drupalData?.relationships?.field_view_gallery?.data?.id;
    if ( unitViewGalleryId ) {
      const unitViewGalleryData = drupalIncludedById[unitViewGalleryId];
      const unitViewGalleryRelationships = unitViewGalleryData?.relationships;
      const unitViewGalleryImagesData = unitViewGalleryRelationships.field_gallery_photos?.data;
      if ( unitViewGalleryImagesData ) {
        unitViewGalleryImagesData.forEach( ( item ) => {
          const image = {
            id: item?.id,
            caption: item?.meta?.alt,
            image: {
              modal: item?.meta?.imageDerivatives?.links?.modal?.href,
            },
          };
          unitViewPhotoGalleryImages.push( image );
        } );
      }
    }

    if ( unitViewPhotoGalleryImages?.length > 0 ) {
      const viewItem = {
        type: 'PhotoGallery',
        data: unitViewPhotoGalleryImages,
      };
      view.push( viewItem );
    }

    if ( view.length > 0 ) {
      node.view = view;
    }
  }

  return node;
};
