import React, {
  useContext, useEffect, useState,
} from 'react';
import classnames from 'classnames';
import Cookies from 'universal-cookie';

import { joinNames } from '../../utils/common';
import { patchResidence } from './ResidenceHelpers';
import ConfigContext from '../../utils/ConfigContext/ConfigContext';
import MapContext from '../MapPage/MapContext';

import './ResidenceStatusWidget.scss';

const cookies = new Cookies();

const ResidenceStatusWidget = ( {
  residenceUuid,
  residenceTitle,
  residentUuids,
  residentNamesString,
  statusUuid,
  residenceStatus,
  setFetchUnitData,
  setDetailDialogData,
} ) => {
  const [ showOccupants, setShowOccupants ] = useState( false );
  const [ showOccupancy, setShowOccupancy ] = useState( false );
  const [ statusPhrase, setStatusPhrase ] = useState( '' );
  const [ dropdownIsOpen, setDropdownIsOpen ] = useState( false );
  const [ changeStatusIsOpen, setChangeStatusIsOpen ] = useState( false );
  const [ allowStatusChange, setAllowStatusChange ] = useState( false );
  const [ buttonText, setButtonText ] = useState( 'Change Status' );
  const [ selectedStatusObject, setSelectedStatusObject ] = useState( {} );
  const [ activeStatusObject, setActiveStatusObject ] = useState( {} );

  const {
    config,
    featureFlags,
    isKiosk,
    isLoggedIn,
    personalizationActive,
    settings,
  } = useContext( ConfigContext );
  const { isPIB } = config;
  const { isSiteSee } = settings;

  const {
    residenceStatusOptions,
    setChangedUnits,
  } = useContext( MapContext );

  // Allow status change if a sales person is logged in.
  useEffect( () => {
    if ( isLoggedIn ) {
      setAllowStatusChange( true );
    }
  }, [ isLoggedIn ] );

  // Should we show occupant names?
  useEffect( () => {
    let show = false;
    if ( isKiosk ) {
      show = featureFlags?.includes( 'kiosk_show_occupants' );
    } else if ( isPIB ) {
      show = featureFlags?.includes( 'pib_show_occupants' );
    } else if ( isSiteSee ) {
      show = featureFlags?.includes( 'sitesee_show_occupants' );
    } else {
      show = featureFlags?.includes( 'yt_show_occupants' );
    }
    setShowOccupants( show );
  }, [
    isKiosk,
    isPIB,
    isSiteSee,
    featureFlags,
  ] );

  // Should we show occupancy status?
  useEffect( () => {
    let show = false;
    if ( isKiosk ) {
      show = featureFlags?.includes( 'kiosk_show_occupancy' );
    } else if ( isPIB ) {
      show = featureFlags?.includes( 'pib_show_occupancy' );
    } else if ( isSiteSee ) {
      show = featureFlags?.includes( 'sitesee_show_occupancy' );
    } else {
      show = featureFlags?.includes( 'yt_show_occupancy' );
    }
    setShowOccupancy( show );
  }, [
    isKiosk, isPIB, isSiteSee, featureFlags,
  ] );

  // Set initial selected status option and active status option.
  useEffect( () => {
    const initialOption = residenceStatusOptions.find(
      ( option ) => option.name === residenceStatus,
    );
    setSelectedStatusObject( initialOption );
    setActiveStatusObject( initialOption );
  }, [] );

  // Set status phrase - "Status: [status] for [residents]" phrase.
  useEffect( () => {
    let rawStatusPhrase = showOccupancy ? residenceStatus : null;
    if ( residentNamesString && residentNamesString !== '' && showOccupants ) {
      switch ( residenceStatus ) {
        case 'Sold':
          rawStatusPhrase = `Sold to ${residentNamesString}`;
          break;
        case 'Reserved':
          rawStatusPhrase = `Reserved for ${residentNamesString}`;
          break;
        case 'Occupied':
          rawStatusPhrase = `Occupied by ${residentNamesString}`;
          break;
        default:
          rawStatusPhrase = `${residenceStatus} for ${residentNamesString}`;
          break;
      }
    }
    setStatusPhrase( rawStatusPhrase );
  }, [
    residenceStatus,
    residentNamesString,
    showOccupancy,
    showOccupants,
  ] );

  // Set button text.
  useEffect( () => {
    if ( personalizationActive && ( !residentNamesString || residentNamesString === '' ) ) {
      setButtonText( 'Make it Yours' );
    }
  }, [ personalizationActive ] );

  // Create status change message.
  const makeSuccessMessage = async ( updatedResidence, updatedResidenceStatusTerm ) => {
    const updatedResidenceStatusName = updatedResidenceStatusTerm?.name;
    let message = '';
    if ( !personalizationActive ) {
      message = `Residence ${residenceTitle}'s status has been updated to ${updatedResidenceStatusName}.`;
    } else {
      // We could generate this based on component data, but let's use the
      // response to confirm Drupal really did update correctly.
      const updatedResidenceTitle = updatedResidence?.data?.attributes?.title;
      const updatedResidenceStatusConsideredUnoccupied = updatedResidenceStatusTerm
        ?.fieldConsiderUnoccupied;

      if ( updatedResidenceStatusConsideredUnoccupied ) {
        message = `Residence ${updatedResidenceTitle}'s status has been updated to ${updatedResidenceStatusName}.`;
      } else {
        // Get names from cookie.
        const { first_name, person2_first_name } = cookies.get( 'resident' );
        const combinedFirstNames = joinNames( first_name, null, person2_first_name, null, null, '&' );
        const namePhrase = combinedFirstNames ? ` ${combinedFirstNames}` : '';
        const lcStatus = updatedResidenceStatusName.toLowerCase();

        message = `Congratulations${namePhrase}! `;
        switch ( updatedResidenceStatusName ) {
          case 'Sold':
          case 'Occupied':
            message += `Residence ${updatedResidenceTitle} is now yours.`;
            break;
          // Reserved, On Hold, etc.
          default:
            message += `Residence ${updatedResidenceTitle} is now ${lcStatus} for you.`;
            break;
        }
      }
    }
    return message;
  };

  const handleOptionClick = ( option ) => {
    setSelectedStatusObject( option );
    setDropdownIsOpen( false );
  };

  const handleSaveClick = async () => {
    // Set active status but we can't rely on this for the rest of the function,
    // so we need to use selectedStatusObject.
    setActiveStatusObject( selectedStatusObject );

    // Close status widget.
    setDropdownIsOpen( false );
    setChangeStatusIsOpen( false );

    // Potential new residents.
    const cookieData = cookies.get( 'resident' );

    const activePersonalizationResidentUuids = [];
    // Check if we are adding or removing residents.
    if ( cookieData && !selectedStatusObject?.fieldConsiderUnoccupied ) {
      activePersonalizationResidentUuids.push( cookieData.drupal_uuid );
      if ( cookieData.person2_drupal_uuid ) {
        activePersonalizationResidentUuids.push( cookieData.person2_drupal_uuid );
      }
    }

    // Sort uuids just so we can compare them.
    activePersonalizationResidentUuids.sort();
    residentUuids.sort();

    // Don't do anything if the status didn't change
    // AND the residents didn't change
    if ( selectedStatusObject.id === statusUuid
      && ( activePersonalizationResidentUuids === residentUuids )
    ) {
      return;
    }

    // Send residence update to Drupal.
    let response = null;

    // If personalization is active.
    if ( cookieData ) {
      // Update residence. This will also update the residents.
      // For now, we are not going to bother removing them from other
      // residences. This will kick current residents out if they aren't the
      // active personalization resident.
      response = await patchResidence(
        residenceUuid,
        activePersonalizationResidentUuids,
        selectedStatusObject.id,
      );
    } else {
      // If personalization not active.
      response = await patchResidence(
        residenceUuid,
        null,
        selectedStatusObject.id,
      );
    }

    // Alert user that residence(s) has been updated or if there's an error.
    let errorMessage = '';
    let updatedResidence = null;
    let updatedResidenceStatusTerm = null;
    if ( response ) {
      // Error
      if ( response?.errors ) {
        response.errors.forEach( ( responseError ) => {
          errorMessage += `${responseError.detail}. `;
        } );
      } else {
        updatedResidence = response;

        // Check that the returned status matches what we sent.
        const updatedResidenceStatusUuid = updatedResidence
          ?.data?.relationships?.field_status?.data?.id;
        updatedResidenceStatusTerm = residenceStatusOptions.find(
          ( option ) => option.id === updatedResidenceStatusUuid,
        );

        if ( updatedResidenceStatusUuid !== selectedStatusObject.id ) {
          errorMessage += 'Residence status did not update correctly. ';
        }
        if ( !updatedResidenceStatusTerm ) {
          errorMessage += 'Residence does not have an assigned status. ';
        }

        // Check that the returned residents matches what we sent.
        const updatedResidenceStatusConsideredUnoccupied = updatedResidenceStatusTerm
          ?.fieldConsiderUnoccupied;
        const rawUpdatedResidenceResidentUuids = updatedResidence
          ?.data?.relationships?.field_residents?.data;
        const comparableUpdatedResidenceResidentUuids = [];
        rawUpdatedResidenceResidentUuids?.forEach( ( resident ) => {
          comparableUpdatedResidenceResidentUuids.push( resident.id );
        });

        // Sort array so we can compare it to the active personalization uuids.
        comparableUpdatedResidenceResidentUuids.sort();

        // If the unit isn't now considered unoccupied and there are active
        // personalization uuids
        if ( !updatedResidenceStatusConsideredUnoccupied
          && activePersonalizationResidentUuids.length > 0 ) {
          if ( comparableUpdatedResidenceResidentUuids === activePersonalizationResidentUuids ) {
            errorMessage += 'Residence occupants did not update correctly. ';
          }
        } else if ( updatedResidenceStatusConsideredUnoccupied
          && comparableUpdatedResidenceResidentUuids?.length > 0 ) {
          // The updated unit is considered unoccupied so there shouldn't be any uuids.
          errorMessage += 'Residence occupants did not update correctly. ';
        }
      }
    } else {
      // Nothing returned - that's an issue too.
      errorMessage += 'Something went wrong. ';
    }

    // There are errors so show error message.
    if ( errorMessage !== '' ) {
      errorMessage += 'Please reload the page and try again.';
      setDetailDialogData( {
        status: 'error',
        message: errorMessage,
      } );
    } else {
      // Success!
      const message = await makeSuccessMessage( updatedResidence, updatedResidenceStatusTerm );
      setDetailDialogData( {
        status: 'success',
        message,
      } );

      // Reload the residence data.
      setFetchUnitData( true );

      // Let the map and filters know about the status change.
      setChangedUnits( [ updatedResidence ] );
    }
  };

  const handleCancelClick = () => {
    const initialOption = residenceStatusOptions.find(
      ( option ) => option.name === residenceStatus,
    );
    setActiveStatusObject( initialOption );
    setDropdownIsOpen( false );
    setChangeStatusIsOpen( false );
  };

  return showOccupancy && (
    <div
      className={
        classnames( 'residenceStatusWidget', {
          'residenceStatusWidget--changeStatusIsOpen': changeStatusIsOpen,
        } )
      }
    >
      <div className="residenceStatusWidget__statusContainer">

        <div className="detailTable-list__item__label residenceStatusWidget__label">
          Status:
        </div>

        { !allowStatusChange && (
          <div className="detailTable-list__item__data">
            { statusPhrase }
          </div>
        )}

        { allowStatusChange && (
          <>
            <div
              className={
                classnames( 'residenceStatusWidget__status', {
                  'residenceStatusWidget__status--active': !changeStatusIsOpen,
                } )
              }
            >

              <div className="detailTable-list__item__data residenceStatusWidget__statusPhrase">
                { statusPhrase }
              </div>

              <div className="residenceStatusWidget__reserveBtnWrapper">
                <button
                  className="residenceStatusWidget__reserveBtn"
                  type="button"
                  onClick={() => {
                    setChangeStatusIsOpen( true );
                  }}
                >
                  { buttonText }
                </button>
              </div>

            </div>

            <div
              className={
                classnames( 'residenceStatusWidget__changeStatus', {
                  'residenceStatusWidget__changeStatus--active': changeStatusIsOpen,
                } )
              }
            >

              <div className="residenceStatusWidget__dropdown">

                { !dropdownIsOpen && (
                  <button
                    type="button"
                    className="residenceStatusWidget__dropdownTrigger"
                    onClick={() => {
                      setDropdownIsOpen( !dropdownIsOpen );
                    }}
                  >
                    { selectedStatusObject.name }
                  </button>
                )}

                { dropdownIsOpen && (
                  <div className="residenceStatusWidget__dropdownOptions">

                    { residenceStatusOptions.map( ( option ) => (
                      <button
                        key={option.id}
                        type="button"
                        className={
                          classnames(
                            'residenceStatusWidget__dropdownOption',
                            { 'residenceStatusWidget__dropdownOption--active': option.name === selectedStatusObject.name },
                          )
                        }
                        onClick={() => {
                          handleOptionClick( option );
                        }}
                      >
                        { option.name }
                      </button>
                    ) )}
                  </div>
                )}

              </div>

              <div className="residenceStatusWidget__actionBtnsWrapper">
                <button
                  type="button"
                  className="residenceStatusWidget__save"
                  onClick={() => {
                    handleSaveClick();
                  }}
                >
                  Save
                </button>

                <button
                  type="button"
                  className="residenceStatusWidget__cancel"
                  onClick={() => {
                    handleCancelClick();
                  }}
                >
                  Cancel
                </button>
              </div>

            </div>
          </>
        )}

      </div>
    </div>
  );
};

export default ResidenceStatusWidget;
