import React, {
  useRef, useState, useEffect, useContext,
} from 'react';

import Fuse from 'fuse.js';

import { decodeHtml } from '../../../utils/common';
import ConfigContext from '../../../utils/ConfigContext/ConfigContext';
import MapContext from '../MapContext';
import { getAuthClient } from '../../../utils/auth';

import PanelSlider from '../PanelSlider/PanelSlider';
import SlidingPanel from '../PanelSlider/SlidingPanel';
import LevelsOfCareButtonList from './LevelsOfCare/LevelsOfCareButtonList';
import CategoryButtonsList from './CategoryButtons/CategoryButtonsList';
import DetailsButtonsList from './DetailsButtonsList/DetailsButtonsList';
import SiteSeeTipTrigger from '../../SiteSee/SiteSeeGuides/SiteSeeTipTrigger';

import './MapFilterMenu.scss';
import '../../../GlobalScss/common.scss';

const auth = getAuthClient();

const MapFilterMenu = ( {
  hideByFieldName,
  siteSeeGuideId,
  setSiteSeeGuideId,
  setShowSiteSeeGuide,
  siteSeeGuideMode,
  setSiteSeeModalDims,
} ) => {
  const [ activePanelIndex, setActivePanelIndex ] = useState( 0 );
  const [ filterInlineStyle, setFilterInlineStyle ] = useState( {} );
  const [ fuseIndex, setFuseIndex ] = useState( null );
  const [ hasActiveFilters, setHasActiveFilters ] = useState( false );
  const [ isMenuOpen, setIsMenuOpen ] = useState( false );
  const [ levelOfCareNames, setLevelOfCareNames ] = useState( {} );
  const [ locFilterData, setLocFilterData ] = useState( {} );
  const [ mapFilterData, setMapFilterData ] = useState( {} );
  const [ originalFilterData, setOriginalFilterData ] = useState( {} );

  const { config } = useContext( ConfigContext );
  const { communityNid, defaultLevelOfCare } = config || {};
  const {
    activeCategoryFilter,
    activeFilterSelections,
    changedUnits,
    filtersVisible,
    residenceStatusOptions,
    setActiveFilterSelections,
    setActiveLevelOfCareUuid,
    setMapActiveUnitData,
    setResultFloorPlans,
  } = useContext( MapContext );

  /**
   * Formats the filter data into an object with hierarchy.
   *
   * @param {array} data
   *   Data in the format as it comes from Drupal.
   *
   * @returns {array}
   *   Returns formatted array for use in the map filter.
   */
  const formatFilteredData = ( data ) => {
    const levelOfCare = {};
    const units = {};
    const allLevelsOfCare = {};

    data?.forEach( ( rawItem ) => {
      // When the results are from Fusejs, they have an 'item' property,
      // so normalize this so that this function works with original data
      // and filtered data.
      let item = rawItem;
      if ( Object.prototype.hasOwnProperty.call( item, 'item' ) ) {
        item = item.item;
      }

      const unitSvgId = item?.field_svg_id;

      if ( Object.prototype.hasOwnProperty.call( item, 'field_svg_id' ) ) {
        units[unitSvgId] = {
          uuid: item.residence_uuid,
        };
      }

      // We're splitting the string up to get the UUID and label. Data
      // comes back from api in the format 'uuid - label'.
      // Make sure the data from Drupal also has a separator with a space on
      // either side.
      const [ locUuid, locLabel ] = item.level_of_care.split( ' - ' );

      // Add Level of Care Uuid to array. We're recording it here because we
      // want the actual filter to show all available LOCS, not just the ones
      // that match later filter panels.

      if ( locUuid && !Object.prototype.hasOwnProperty.call( levelOfCare, locUuid ) ) {
        levelOfCare[locUuid] = {
          label: locLabel ?? '',
          children: {
            type: { label: 'Types', id: 'types', children: {} },
            bedrooms: { label: 'Bedrooms', id: 'bedrooms', children: {} },
            location: { label: 'Locations', id: 'locations', children: {} },
            floor_plan: { label: 'Floor Plans', id: 'floorPlans', children: {} },
            occupancy: { label: 'Occupancy', id: 'occupancy', children: {} },
            level_of_care: { label: 'Levels of Care', id: 'levelsOfCare', children: {} },
          },
        };

        units[unitSvgId].level_of_care = locUuid;
        // Drupal won't let you rename REST properties from a View, so Level of
        // Care Weight is weight_1.
        // Weight comes over as string, so we convert it to a number.
        const rawWeight = parseInt( item.weight_1, 10 );
        const weight = Number.isNaN( rawWeight ) ? 100 : rawWeight;
        allLevelsOfCare[locUuid] = {
          label: decodeHtml( locLabel ) ?? '',
          weight,
        };
      }

      if ( Object.prototype.hasOwnProperty.call( item, 'bedrooms' ) ) {
        const [ bedroomsUuid, bedroomsLabel ] = ( item.bedrooms && item.bedrooms.length ) ? item.bedrooms.split( ' - ' ) : '';

        let bedroomWeight = 100;
        switch ( bedroomsUuid ) {
          case 'studio':
            bedroomWeight = 1;
            break;
          case 'one-bedroom':
            bedroomWeight = 2;
            break;
          case 'w-den-one-bedroom':
            bedroomWeight = 3;
            break;
          case 'two-bedrooms':
            bedroomWeight = 4;
            break;
          case 'w-den-two-bedrooms':
            bedroomWeight = 5;
            break;
          case 'three-bedrooms':
            bedroomWeight = 6;
            break;
          case 'w-den-three-bedrooms':
            bedroomWeight = 7;
            break;
          default:
        }

        levelOfCare[locUuid].children.bedrooms.children[bedroomsUuid] = {
          label: bedroomsLabel,
          uuid: bedroomsUuid,
          weight: bedroomWeight,
          meta: item,
        };

        units[unitSvgId].bedrooms = bedroomsUuid;
      }

      // Locations data.
      if ( Object.prototype.hasOwnProperty.call( item, 'location' ) ) {
        const [ locationUuid, locationLabel ] = ( item.location && item.location.length ) ? item.location.split( ' - ' ) : '';

        // Drupal won't let you rename REST properties from a View, so Location
        // Weight is weight_2.
        // Weight comes over as string, so we convert it to a number.
        const rawWeight = parseInt( item.weight_2, 10 );
        const weight = Number.isNaN( rawWeight ) ? 100 : rawWeight;
        levelOfCare[locUuid].children.location.children[locationUuid] = {
          label: decodeHtml( locationLabel ),
          uuid: locationUuid,
          weight,
          meta: item,
        };

        units[unitSvgId].location = locationUuid;
      }

      // Types data.
      if ( Object.prototype.hasOwnProperty.call( item, 'type' ) ) {
        const [ typeUuid, typeLabel ] = ( item.type && item.type.length ) ? item.type.split( ' - ' ) : '';
        // Drupal won't let you rename REST properties from a View, so Floor
        // Plan Type Weight is weight.
        // Weight comes over as string, so we convert it to a number.
        const rawWeight = parseInt( item.weight, 10 );
        const weight = Number.isNaN( rawWeight ) ? 100 : rawWeight;
        levelOfCare[locUuid].children.type.children[typeUuid] = {
          label: decodeHtml( typeLabel ),
          uuid: typeUuid,
          weight,
          meta: item,
        };

        units[unitSvgId].type = typeUuid;
      }

      // Occupancy Data.
      if ( Object.prototype.hasOwnProperty.call( item, 'occupancy' ) ) {
        const [ occupancyUuid, occupancyLabel ] = ( item.occupancy && item.occupancy.length ) ? item.occupancy.split( ' - ' ) : '';

        // Drupal won't let you rename REST properties from a View, so Status
        // Weight is weight_4.
        // Weight comes over as string, so we convert it to a number.
        const rawWeight = parseInt( item.weight_4, 10 );
        const weight = Number.isNaN( rawWeight ) ? 100 : rawWeight;
        levelOfCare[locUuid].children.occupancy.children[occupancyUuid] = {
          label: decodeHtml( occupancyLabel ),
          uuid: occupancyUuid,
          weight,
          meta: item,
        };

        units[unitSvgId].occupancy = occupancyUuid;
      }

      // Floor Plan Data.
      if ( Object.prototype.hasOwnProperty.call( item, 'floor_plan' ) ) {
        const [ floorPlanUuid, floorPlanLabel ] = ( item.floor_plan && item.floor_plan.length ) ? item.floor_plan.split( ' - ' ) : '';

        // Drupal sends booleans over as strings, so I just went with human
        // words.
        // Drupal won't let you rename REST properties from a View, so Floor
        // Plan Weight is weight_4.
        // Weight comes over as string, so we convert it to a number.
        const rawWeight = parseInt( item.weight_4, 10 );
        const weight = Number.isNaN( rawWeight ) ? 100 : rawWeight;
        if ( !hideByFieldName || item[hideByFieldName] !== 'No' ) {
          levelOfCare[locUuid].children.floor_plan.children[floorPlanUuid] = {
            label: decodeHtml( floorPlanLabel ),
            uuid: floorPlanUuid,
            weight,
            meta: item,
          };
        }

        units[unitSvgId].floor_plan = floorPlanUuid;
      }
    } );

    return {
      levelOfCare,
      units,
      allLevelsOfCare,
    };
  };

  const toggleFilterMenu = ( e ) => {
    const filterToggleBtn = e.target;

    // Open/Shut menu by managing state and toggling classes.
    setIsMenuOpen( !isMenuOpen );
    filterToggleBtn.classList.toggle( 'toggleArrow--up--active' );

    // Toggle class on the SVG icon.
    filterToggleBtn.querySelectorAll( '.toggleArrow-svg' )[0].classList.toggle( 'toggleArrow-svg--active' );

    // Toggle class on the button's parent element.
    filterToggleBtn.parentElement.classList.toggle( 'mapSelector-btn--active' );
  };

  // Fetch Map Filter Data from Drupal and create Fusejs search index.
  useEffect( () => {
    const fetchData = async () => {
      const url = `/your_tour_api/map-filter-options?community=${communityNid}`;
      const data = await auth.fetchWithAuthentication( url, 'GET', null );
      const { levelOfCare, units, allLevelsOfCare } = formatFilteredData( data );

      // Save the original unfiltered data
      setOriginalFilterData( levelOfCare );

      // Set up Fuse search index and search keys.
      const fuseOptions = {
        includeScore: true,
        threshold: 0.0,
        keys: [
          'bedrooms',
          'floor_plan',
          'level_of_care',
          'location',
          'occupancy',
          'type',
        ],
      };

      // Save indexed data for filter.
      const fuse = new Fuse( data, fuseOptions );

      setFuseIndex( fuse );
      setMapFilterData( levelOfCare );
      setLevelOfCareNames( allLevelsOfCare );

      setLocFilterData( levelOfCare );

      // Save indexed data for determining active units for Interactive Map only.
      if ( setMapActiveUnitData ) {
        setMapActiveUnitData( units );
      }
    };

    if ( communityNid ) {
      fetchData();
    }
  }, [ communityNid ] );

  // Update filter data when a residence status is changed on the YT side.
  useEffect( () => {
    if ( changedUnits?.length > 0 ) {
      const fuseDocs = fuseIndex?.getIndex().docs;

      changedUnits?.forEach( ( changedUnit ) => {
        const changedUnitSvgId = changedUnit?.data?.attributes?.field_svg_id;
        const changedUnitRelationships = changedUnit?.data?.relationships;

        const fuseDoc = fuseDocs.find(
          ( doc ) => doc.field_svg_id === changedUnitSvgId,
        );
        const fuseDocCopy = structuredClone( fuseDoc );

        // Remove stale unit from the index.
        fuseIndex.remove( ( doc ) => doc.field_svg_id === changedUnitSvgId );

        // Add refreshed unit back to index.
        if ( fuseDocCopy ) {
          const changedUnitStatusUuid = changedUnitRelationships?.field_status?.data?.id;
          const changedUnitStatusPhrase = residenceStatusOptions.find(
            ( status ) => status.id === changedUnitStatusUuid,
          );
          // Format: Uuid - Label
          const changedUnitStatus = `${changedUnitStatusUuid} - ${changedUnitStatusPhrase?.name}`;
          fuseDocCopy.occupancy = changedUnitStatus;
          fuseIndex.add( fuseDocCopy );
        }
      } );

      // Update active units for Interactive Map only.
      if ( Object.keys( activeFilterSelections ).length ) {
        doMapFilterSearch( activeFilterSelections );
      } else {
        doMapFilterSearch();
      }
    }
  }, [ changedUnits ] );

  // Toggle visiblity of filters with CSS rather than rendering so we can
  // maintain selections between zooms.
  useEffect( () => {
    if ( filtersVisible ) {
      setFilterInlineStyle( { display: 'flex' } );
    } else {
      setFilterInlineStyle( { display: 'none' } );
    }
  }, [ filtersVisible ] );

  // When the user returns to the LOC panel, clear active filters.
  useEffect( () => {
    if ( activePanelIndex === 0 ) {
      // Set active selections to an empty object.
      setActiveFilterSelections( {} );

      // Set map back to default Level of Care
      setActiveLevelOfCareUuid( defaultLevelOfCare );
    }
  }, [ activePanelIndex, defaultLevelOfCare ] );

  /**
   * Helper function to get UUIDs of a type within the results.
   *
   * @param {string} type
   *   A Category type string. E.g. 'location', 'floor_plan', etc.
   *
   * @param {array} results
   *   Filtered Results set that you want to get values from.
   *
   * @returns {array}
   *   Array of unique UUIDs of a type within the Filtered Results.
   */
  const getResultsByType = ( type, results ) => results.map( ( result ) => {
    // When the results are from Fusejs, they have an 'item' property,
    // so normalize this so that this function works with original data
    // and filtered data.
    let returnResult = result;
    if ( Object.prototype.hasOwnProperty.call( result, 'item' ) ) {
      returnResult = result.item;
    }

    // Split the string up so we return only the UUID.
    return returnResult[type].split( ' - ' )[0];
  } ).filter( ( result, index, allResults ) => allResults.indexOf( result ) === index );
  // Filter so we only have unique values in the array.

  /**
   * Perform search against the Fuse index and returns formatted data.
   */
  const doMapFilterSearch = (params) => {
    if (fuseIndex) {
      let resultAll = [];
      let resultForFloorPlan = [];
      let resultForBedrooms = [];
      let resultForType = [];
      let resultForLocation = [];
      let resultForOccupancy = [];
      const allDocs = fuseIndex.getIndex().docs;

      if (params) {
        // Create filter parameters for all categories (for non–self‑free merging)
        const allFilterParams = { ...params };

        // For multi-select facets, we always want to ignore their own selections when
        // computing available options. That way, if a user has selected a floor plan,
        // for example, it doesn't force the facet to show only those floor plans.
        // ---
        // Floor Plan:
        const selfFreeFloorPlanParams = { ...params };
        if (selfFreeFloorPlanParams.floor_plan) {
          delete selfFreeFloorPlanParams.floor_plan;
        }
        resultForFloorPlan = allDocs.filter(doc =>
          Object.entries(selfFreeFloorPlanParams).every(([category, selections]) => {
            const docCategoryUuid = doc[category].split(' - ')[0];
            return Object.keys(selections).some(
              (selectedUuid) => selectedUuid === docCategoryUuid
            );
          })
        );

        // Bedrooms: (This one is working as expected, so we retain the conditional behavior.)
        if (activeCategoryFilter === 'bedrooms') {
          const selfFreeBedroomParams = { ...params };
          if (selfFreeBedroomParams.bedrooms) {
            delete selfFreeBedroomParams.bedrooms;
          }
          resultForBedrooms = allDocs.filter(doc =>
            Object.entries(selfFreeBedroomParams).every(([category, selections]) => {
              const docCategoryUuid = doc[category].split(' - ')[0];
              return Object.keys(selections).some(
                (selectedUuid) => selectedUuid === docCategoryUuid
              );
            })
          );
        } else {
          resultForBedrooms = allDocs.filter(doc =>
            Object.entries(allFilterParams).every(([category, selections]) => {
              const docCategoryUuid = doc[category].split(' - ')[0];
              return Object.keys(selections).some(
                (selectedUuid) => selectedUuid === docCategoryUuid
              );
            })
          );
        }

        // Type:
        const selfFreeTypeParams = { ...params };
        if (selfFreeTypeParams.type) {
          delete selfFreeTypeParams.type;
        }
        resultForType = allDocs.filter(doc =>
          Object.entries(selfFreeTypeParams).every(([category, selections]) => {
            const docCategoryUuid = doc[category].split(' - ')[0];
            return Object.keys(selections).some(
              (selectedUuid) => selectedUuid === docCategoryUuid
            );
          })
        );

        // Location:
        const selfFreeLocationParams = { ...params };
        if (selfFreeLocationParams.location) {
          delete selfFreeLocationParams.location;
        }
        resultForLocation = allDocs.filter(doc =>
          Object.entries(selfFreeLocationParams).every(([category, selections]) => {
            const docCategoryUuid = doc[category].split(' - ')[0];
            return Object.keys(selections).some(
              (selectedUuid) => selectedUuid === docCategoryUuid
            );
          })
        );

        // Occupancy:
        const selfFreeOccupancyParams = { ...params };
        if (selfFreeOccupancyParams.occupancy) {
          delete selfFreeOccupancyParams.occupancy;
        }
        resultForOccupancy = allDocs.filter(doc =>
          Object.entries(selfFreeOccupancyParams).every(([category, selections]) => {
            const docCategoryUuid = doc[category].split(' - ')[0];
            return Object.keys(selections).some(
              (selectedUuid) => selectedUuid === docCategoryUuid
            );
          })
        );

        // Also compute a general "all" result that applies every filter (including those that aren't multi‑select)
        resultAll = allDocs.filter(doc =>
          Object.entries(allFilterParams).every(([category, selections]) => {
            const docCategoryUuid = doc[category].split(' - ')[0];
            return Object.keys(selections).some(
              (selectedUuid) => selectedUuid === docCategoryUuid
            );
          })
        );

        // If no results are returned, remove filter option that had no results.
        // This only happens when a unit's status is changed in YT and it was the only
        // one of it's status - leaving the filter results empty.
        // This will trigger a new search with no occupancy filter.
        if ( resultAll.length === 0 ) {
          setActiveFilterSelections( {} );
        }

        // Format the filtered data sets.
        const { levelOfCare: filteredLevelOfCareAll, units } = formatFilteredData(resultAll);
        const { levelOfCare: filteredLevelOfCareFloorPlan } = formatFilteredData(resultForFloorPlan);
        const { levelOfCare: filteredLevelOfCareBedrooms } = formatFilteredData(resultForBedrooms);
        const { levelOfCare: filteredLevelOfCareType } = formatFilteredData(resultForType);
        const { levelOfCare: filteredLevelOfCareLocation } = formatFilteredData(resultForLocation);
        const { levelOfCare: filteredLevelOfCareOccupancy } = formatFilteredData(resultForOccupancy);

        // Merge the filtered data with the original filter data.
        // For the multi-select facets (floor_plan, type, location, occupancy) we now always use the self‑free result.
        const mergedLevelOfCare = {};
        Object.keys(originalFilterData).forEach(locKey => {
          if (originalFilterData[locKey]) {
            mergedLevelOfCare[locKey] = {
              ...originalFilterData[locKey],
              children: {
                type: {
                  ...originalFilterData[locKey].children.type,
                  children: filteredLevelOfCareType[locKey]?.children?.type?.children || filteredLevelOfCareAll[locKey]?.children?.type?.children || {}
                },
                bedrooms: {
                  ...originalFilterData[locKey].children.bedrooms,
                  children: activeCategoryFilter === 'bedrooms'
                    ? filteredLevelOfCareBedrooms[locKey]?.children?.bedrooms?.children || {}
                    : filteredLevelOfCareAll[locKey]?.children?.bedrooms?.children || {}
                },
                location: {
                  ...originalFilterData[locKey].children.location,
                  children: filteredLevelOfCareLocation[locKey]?.children?.location?.children || filteredLevelOfCareAll[locKey]?.children?.location?.children || {}
                },
                floor_plan: {
                  ...originalFilterData[locKey].children.floor_plan,
                  children: filteredLevelOfCareFloorPlan[locKey]?.children?.floor_plan?.children || {}
                },
                occupancy: {
                  ...originalFilterData[locKey].children.occupancy,
                  children: filteredLevelOfCareOccupancy[locKey]?.children?.occupancy?.children || filteredLevelOfCareAll[locKey]?.children?.occupancy?.children || {}
                },
                level_of_care: {
                  ...originalFilterData[locKey].children.level_of_care,
                  children: activeCategoryFilter === 'level_of_care'
                    ? originalFilterData[locKey].children.level_of_care.children
                    : filteredLevelOfCareAll[locKey]?.children?.level_of_care?.children || {}
                }
              }
            };
          }
        });

        // Update the UI with the merged filter data.
        setMapFilterData(mergedLevelOfCare);
        if (setMapActiveUnitData) {
          setMapActiveUnitData(units);
        }
        if (setResultFloorPlans) {
          const filteredFloorPlans = getResultsByType('floor_plan', resultAll);
          setResultFloorPlans(filteredFloorPlans);
        }
      } else {
        // If no filters are active, revert to original data.
        setMapFilterData(originalFilterData);
        if (setMapActiveUnitData) {
          const { units } = formatFilteredData(allDocs);
          setMapActiveUnitData(units);
        }
        if (setResultFloorPlans) {
          const filteredFloorPlans = getResultsByType('floor_plan', allDocs);
          setResultFloorPlans(filteredFloorPlans);
        }
      }
    }
  };

  // When the active selections are made, filter the remaining data that should
  // be visible to the user. If there are no active filters, reset back
  // to original dataset.
  useEffect( () => {
    if ( Object.keys( activeFilterSelections ).length ) {
      doMapFilterSearch( activeFilterSelections );
    } else {
      doMapFilterSearch();
    }
    setHasActiveFilters( Object.keys( activeFilterSelections ).length > 0 );
  }, [ activeFilterSelections ] );

  // Pass ref back to sitesee guide.
  const arrowRef = useRef( null );
  useEffect( () => {
    const currentRef = arrowRef.current;
    if ( currentRef
      && ( siteSeeGuideId === 4 || siteSeeGuideId === 'filters' )
    ) {
      setSiteSeeModalDims( currentRef.getBoundingClientRect() );
    }
  }, [ siteSeeGuideId, filterInlineStyle ] );

  return (
    <div className="mapFilter__wrapper" style={filterInlineStyle}>
      <div className="mapSelector mapFilters">
        <div className="mapSelector-container mapFilter-container">
          <div className="mapSelector-selection mapFilter-selection type-cta-lg">
            Filters
          </div>
          <div className="mapSelector-btn mapFilter-btn" ref={arrowRef}>
            <button
              type="button"
              className="toggleArrow toggleArrow--up"
              onClick={toggleFilterMenu}
            >
              <svg viewBox="0 0 38 38" className="toggleArrow-svg">
                <path d="M30.88 13.06L19 24.94 7.12 13.06z" />
              </svg>
            </button>
          </div>

          <PanelSlider
            isMenuOpen={isMenuOpen}
            activePanelIndex={activePanelIndex}
            setActivePanelIndex={setActivePanelIndex}
          >
            {/* Levels of Care Panel */}
            <SlidingPanel
              index={0}
              activePanelIndex={activePanelIndex}
              setActivePanelIndex={setActivePanelIndex}
            >
              <LevelsOfCareButtonList
                levelOfCareNames={levelOfCareNames}
                goToNextPanel={() => setActivePanelIndex(1)}
                index={0}
              />
            </SlidingPanel>

            {/* Categories Panel */}
            <SlidingPanel
              index={1}
              activePanelIndex={activePanelIndex}
              setActivePanelIndex={setActivePanelIndex}
            >
              <CategoryButtonsList
                mapFilterData={mapFilterData}
                goToNextPanel={() => setActivePanelIndex(2)}
                goToPrevPanel={() => setActivePanelIndex(0)}
                index={1}
                hasActiveFilters={hasActiveFilters}
              />
            </SlidingPanel>

            {/* Details Panel */}
            <SlidingPanel
              index={2}
              activePanelIndex={activePanelIndex}
              setActivePanelIndex={setActivePanelIndex}
            >
              <DetailsButtonsList
                mapFilterData={mapFilterData}
                goToPrevPanel={() => setActivePanelIndex(1)}
                index={2}
              />
            </SlidingPanel>
          </PanelSlider>
        </div>
      </div>

      <SiteSeeTipTrigger
        siteSeeGuideId={siteSeeGuideId}
        setSiteSeeGuideId={setSiteSeeGuideId}
        setShowSiteSeeGuide={setShowSiteSeeGuide}
        siteSeeGuideMode={siteSeeGuideMode}
        siteSeeTipId="filters"
      />
    </div>
  );
};

export default MapFilterMenu;
