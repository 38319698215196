import React, { useContext, useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { getAuthClient } from '../../utils/auth';

import FloorPlansPageListItem from './FloorPlansPageListItem';

import '../../GlobalScss/grid.scss';
import ConfigContext from '../../utils/ConfigContext/ConfigContext';

const auth = new getAuthClient();

const FloorPlansPageList = ( { items } ) => {
  const [ soldOutFps, setSoldOutFps ] = useState( {} );
  const [ combinedFloorPlansData, setCombinedFloorPlansData ] = useState( null );

  const { isKiosk, settings, featureFlags } = useContext( ConfigContext );
  const { urlSlug } = settings;

  // Is the sold out feature enabled for this platform?
  // Should we show pricing? This won't be on /map-embeds so we don't need to
  // check for it.
  let showSoldOut = false;
  if ( isKiosk ) {
    showSoldOut = featureFlags?.includes( 'kiosk_show_sold_out_status' );
  } else {
    showSoldOut = featureFlags?.includes( 'yt_show_sold_out_status' );
  }

  // Get sold out fp data if we need it.
  useEffect( () => {
    const fetchData = async () => {
      const url = `/your_tour_api/floor_plan_availability/${urlSlug}/?_format=json`;
      const data = await auth.fetchWithAuthentication( url, 'GET', null );
      setSoldOutFps( data?.sold_out || [] );
    };
    if ( urlSlug && showSoldOut ) {
      fetchData();
    }
  }, [ urlSlug, showSoldOut ] );

  // Combine the floor plan data sets if we grabbed sold out data above.
  useEffect( () => {
    if ( soldOutFps ) {
      const newFloorPlansData = items.map( ( floorPlan ) => {
        const combinedFloorPlan = floorPlan;
        const inventory = soldOutFps[floorPlan?.id]?.remaining_inventory;
        combinedFloorPlan.remainingInventory = inventory;
        return combinedFloorPlan;
      } );
      setCombinedFloorPlansData( newFloorPlansData );
    } else {
      setCombinedFloorPlansData( items );
    }
  }, [ items, soldOutFps ] );

  return (
    <div className="gridContainer floorplanCat-1">
      { combinedFloorPlansData?.map( ( item ) => (
        <FloorPlansPageListItem
          key={item?.id}
          data={item}
        />
      ) ) }
    </div>
  );
};

FloorPlansPageList.propTypes = {
  items: propTypes.arrayOf( propTypes.shape( {
    id: propTypes.string,
  } ) ),
};

FloorPlansPageList.defaultProps = {
  items: [],
};

export default FloorPlansPageList;
