/* eslint-disable camelcase */
/* drupal sends over data as snake_case */
import React, { useEffect, useState } from 'react';

import { getContrastTextColorValue } from '../../../utils/common';
import './UnitLabel.scss';

const UnitLabel = ( {
  svgData,
  showOccupancy
} ) => {
  const [ textColor, setTextColor ] = useState( 'transparent' );
  const [ textOffset, setTextOffset ] = useState( '0.3em' );

  const { info, label, displayMode } = svgData || {};
  const { label_text, key_color, status } = info || {};
  const { abbreviation } = status || {};

  // Set text color based on the status of the unit and the color of the shape.
  useEffect( () => {
    let rawTextColor = 'transparent';
    switch ( displayMode ) {
      case 'active':
        rawTextColor = getContrastTextColorValue( key_color || '#000000' );
        break;
      case 'inactive':
        rawTextColor = key_color || '#000000';
        break;
      case 'disabled':
        rawTextColor = 'transparent';
        break;
      default:
        rawTextColor = 'transparent';
        break;
    }
    setTextColor( rawTextColor );
  }, [ displayMode, key_color ] );

  // Set fake text offset.
  useEffect( () => {
    if ( abbreviation && abbreviation !== '' && showOccupancy ) {
      setTextOffset( '0' );
    }
  }, [ abbreviation, showOccupancy ] );

  return (
    <g className="unitLabel">

      <text
        { ...label}
        fill={textColor}
        className="unitLabel__text"
      >
        {/*
            baseline isn't supported across all browers
            so we have to fake it with an empty starting tspan and
            dy in following tspan
          */}
        <tspan />
        <tspan
          dy={textOffset}
        >
          {label_text}
        </tspan>
      </text>

      { abbreviation && abbreviation !== '' && showOccupancy
          && (
          <text
            { ...label}
            fill={textColor}
            className="unitLabel__text"
          >
            {/*
              baseline isn't supported across all browers
              so we have to fake it with an empty starting tspan and
              dy in following tspan
            */}
            <tspan />
            <tspan
              dy="1em"
            >
              {abbreviation}
            </tspan>
          </text>
          )}

    </g>
  );
};

export default UnitLabel;
