import { getAuthClient } from '../../utils/auth';

const auth = getAuthClient();

export function assembleResidenceNavBtnsData( unitData ) {
  const navBtns = [];

  if ( !unitData ) {
    return navBtns;
  }

  const {
    roomFees,
    description,
    twoDFloorPlan,
    threeDFloorPlan,
    roomDims,
    virtualTour,
    finishCollection,
    furnitureConfiguratorUrl,
    video,
    view,
    photoGallery,
  } = unitData;

  navBtns.push( {
    section: 'info-scroll',
    text: 'Info',
  } );

  if ( twoDFloorPlan || threeDFloorPlan ) {
    navBtns.push( {
      section: 'floorplan-scroll',
      text: 'Floor Plan',
    } );
  }

  if ( roomDims?.length > 0 ) {
    navBtns.push( {
      section: 'dimensions-scroll',
      text: 'Dimensions',
    } );
  }

  if ( view ) {
    navBtns.push( {
      section: 'view-scroll',
      text: 'View',
    } );
  }

  if ( photoGallery?.images.length > 0 ) {
    navBtns.push( {
      section: 'slideshow-scroll',
      text: 'Photos',
    } );
  }

  if ( video?.serviceVideoId && video?.service ) {
    navBtns.push( {
      section: 'video-scroll',
      text: 'Video',
    } );
  }

  if ( virtualTour?.threeSixties.length > 0 ) {
    navBtns.push( {
      section: 'tour-scroll',
      text: 'Tour',
    } );
  }

  if ( furnitureConfiguratorUrl ) {
    navBtns.push( {
      section: 'configurator-scroll',
      text: 'Furniture Layout',
    } );
  }

  if ( finishCollection && Object.keys( finishCollection ).length !== 0 ) {
    navBtns.push( {
      section: 'finishes-scroll',
      text: 'Finishes',
    } );
  }

  return navBtns;
}

/**
 * Patch residence in Drupal.
 *
 * @param {String} id
 *    Residence UUID.
 * @param {Array} residentUuids
 *    Resident UUIDs.
 * @param {String} statusUuid
 *    Status UUID.
 * @returns {Object}
 */
export async function patchResidence(
  id,
  residentUuids,
  statusUuid,
) {
  if ( !id && (
    ( !residentUuids || residentUuids.length === 0 ) && !statusUuid )
  ) {
    // eslint-disable-next-line no-console
    console.error( 'Cannot patch residence. Missing id, resident uuid(s), or status uuid' );
    return {};
  }

  const data = {
    data: {
      type: 'node--residence',
      id,
    },
  };

  if ( statusUuid || residentUuids ) {
    data.data.relationships = {};
  }

  if ( residentUuids ) {
    const residentRelationshipData = [];
    residentUuids.forEach( ( uuid ) => {
      const residentRelationshipDatum = {
        type: 'node--resident',
        id: uuid,
      };
      residentRelationshipData.push( residentRelationshipDatum );
    } );

    data.data.relationships.field_residents = {
      data: residentRelationshipData,
    };
  }

  if ( statusUuid ) {
    data.data.relationships.field_status = {
      data: {
        type: 'taxonomy_term--residence_status',
        id: statusUuid,
      },
    };
  }

  const body = JSON.stringify( data );
  const updatedResidence = await auth.fetchWithAuthentication(
    `/jsonapi/node/residence/${id}`,
    'PATCH',
    body,
  );

  console.log('updatedResidence', updatedResidence);
  return updatedResidence;
}

// export async function getResidencesWithResidentUuid( residentUuid ) {
//   const url = `/jsonapi/node/residence?filter[field_residents]=${residentUuid}`;
//   const data = await auth.fetchWithAuthentication( url, 'GET', null );
//   return data;
// }
