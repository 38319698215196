const JSONAPIDeserializer = require( 'jsonapi-serializer' ).Deserializer;

/**
 * Normalize function for residence status taxonomy terms.
 */
export default async ( responseData ) => {
  const options = {
    keyForAttribute: 'camelCase',
  };
  const deserializedData = await new JSONAPIDeserializer( options ).deserialize( responseData );
  return deserializedData;
};
