import React from 'react';
import propTypes from 'prop-types';

import FadingContainer
 from '../FadingContainer/FadingContainer';
import './DetailDialog.scss';

const DetailDialog = ( { detailDialogData } ) => {
  const {
    status,
    message
  } = detailDialogData;

  return message && message !== '' && (
    <FadingContainer>
      <div className={ `detailDialog detailDialog--${status}` }>
        <div className="detailDialog__message">
          { message }
        </div>
      </div>
    </FadingContainer>
  );
};

DetailDialog.propTypes = {
  message: propTypes.string,
  status: propTypes.string,
};

DetailDialog.defaultProps = {
  message: null,
  status: null,
};

export default DetailDialog;
