import React, { useRef } from 'react';

import PropTypes from 'prop-types';

const Accordion = ( {
  title,
  children,
  goToPrevPanel,
  createBackButton,
} ) => {
  const contentRef = useRef();

  const contentStyle = {};
  contentStyle.maxHeight = '100%';

  return (
    <div className="mapFilter-panel-options--category">

      { createBackButton && (
        <button
          type="button"
          className="mapFilter-accordion-trigger type-cta-sm"
          onClick={goToPrevPanel}
        >
          {title}
        </button>
      )}

      <div
        ref={contentRef}
        style={contentStyle}
        className="mapFilter-accordion"
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  goToPrevPanel: PropTypes.func.isRequired,
  createBackButton: PropTypes.bool,
};
Accordion.defaultProps = {
  createBackButton: false,
};