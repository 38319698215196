import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import MapContext from '../../MapContext';
import { getContrastTextColorValue } from '../../../../utils/common';

const CategoryButtons = ( { categoryLabel, category, goToNextPanel } ) => {
  const mapContextStuff = useContext( MapContext );
  const {
    setActiveCategoryFilter,
    activeFilterSelections,
    setActiveFilterSelections,
  } = mapContextStuff;

  // @TODO: TBH, I don't remember what this was for. Need to follow up.
  // Display mode options:
  // 'active' --> floor plan is on and matches both LOC and filter
  // 'inactive' --> floor plan is on and matches LOC, but does not match filter
  // 'disabled' --> floor plan is off and does NOT match LOC

  const handleClick = ( event ) => {
    setActiveCategoryFilter( category );
    const clickTarget = event.target;

    // Check that the element clicked *isn't* the close button, then advance
    // to the next menu panel.
    if ( !clickTarget.classList.contains( 'mapFilter-option-tag--remove' ) ) {
      goToNextPanel( event );
    }
  };

  const handleRemoveFilter = ( uuid, event ) => {
    // Stop the click from propagating to parent elements
    event.stopPropagation();

    // Making a deep copy of the filter selection
    const activeFilterSelectionsCopy = { ...activeFilterSelections };

    // Delete the selection
    delete activeFilterSelectionsCopy[category][uuid];

    // If category is now empty, remove it
    if ( Object.keys( activeFilterSelectionsCopy[category] ).length === 0 ) {
      delete activeFilterSelectionsCopy[category];
    }

    setActiveFilterSelections( activeFilterSelectionsCopy );
  };

  // Generate 'tags' on the Category panel with the user's active selections.
  let activeSelectionTags = [];
  if ( activeFilterSelections[category] ) {
    const selections = activeFilterSelections[category];
    const selectionCount = Object.keys( selections ).length;

    if ( selectionCount > 4 ) {
      // If more than 2 selections, show first one and count
      const firstSelection = Object.values( selections )[0];

      // Shorten the label if it is too long.
      let label = category === 'floor_plan' ? firstSelection.label : firstSelection;
      label = label.length > 22 ? `${label.substring( 0, 22 )}...` : label;

      activeSelectionTags = [ (
        <span key="summary" className="mapFilter-option-tag">
          {label}
          {' '}
          +
          {selectionCount - 1}
          {' '}
          more
        </span>
      ) ];
    } else {
      // Show all selections if 2 or fewer
      activeSelectionTags = Object.entries( selections ).map( ( [ uuid, value ] ) => {
        // For floor plans, value is an object with label and color
        // Shorten the label if it is too long.
        let label = category === 'floor_plan' ? value.label : value;
        label = label.length > 22 ? `${label.substring( 0, 22 )}...` : label;

        const style = category === 'floor_plan' ? {
          backgroundColor: value.color,
          borderColor: value.color,
          color: getContrastTextColorValue( value.color ),
        } : {};

        return (
          <span
            key={uuid}
            className="mapFilter-option-tag"
            style={style}
            onClick={( e ) => handleRemoveFilter( uuid, e )}
            role="button"
            tabIndex={0}
            onKeyPress={( e ) => {
              if ( e.key === 'Enter' || e.key === ' ' ) {
                handleRemoveFilter( uuid, e );
              }
            }}
          >
            { label }
            <span className="mapFilter-option-tag--remove" aria-hidden="true" aria-label="Remove filter" />
          </span>
        );
      } );
    }
  }

  return (
    <button
      type="button"
      className="mapSelector-option mapFilter-option type-cta-alt-md mapFilter-option--more"
      onClick={handleClick}
    >
      <span className="mapFilter-optionName">{categoryLabel}</span>
      <div className="mapFilter-option-tags">
        {activeSelectionTags}
      </div>
    </button>
  );
};

CategoryButtons.propTypes = {
  categoryLabel: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  goToNextPanel: PropTypes.func.isRequired,
};

export default CategoryButtons;
