import React from 'react';

const FormattedStickyNoFavoritesBlurb = ( phone, email, contactName ) => {
  const markup = (
    <div className="pibPage-favorites__stickyContent">
      <div className="pibPage-favorites__stickyContent__wysiwyg pibPage-favorites__stickyContent__wysiwyg--centered">
        <p>
          You don’t have any favorites yet. If you think this is an error, contact
          {' '}
          { contactName }
          {' '}
          { email
            && (
            <>
              at
              {' '}
              <a href={`mailto:${email}`}>{email}</a>
            </>
            )}

          { phone && (
            <>
              { email
                ? <> or </>
                : <></>}
              { contactName }
              {' '}
              at
              {' '}
              {phone}
              .
            </>
          )}

          { !email && !phone
            && (
            <>.</>
            )}
        </p>
      </div>
    </div>
  );
  return markup;
};

export default FormattedStickyNoFavoritesBlurb;
