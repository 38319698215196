import React, { useEffect, useState } from 'react';

import DetailHeader from '../Detail/DetailHeader';
import PhotoGalleryDetail from '../PhotoGallery/PhotoGalleryDetail/PhotoGalleryDetail';
import VideoDetail from '../Video/VideoDetail';
import VirtualTourDetail from '../VirtualTour/VirtualTourDetail';
import FadingContainer from '../FadingContainer/FadingContainer';

import '../Detail/Detail.scss';

const SubpaneMediaDetail = ( {
  data,
  navClass,
} ) => (
  <FadingContainer>
    <div id="mediaDetail">

      <DetailHeader
        text={{
          title: data?.title,
          subtitle: null,
        }}
        navBtns={null}
        navClass={navClass}
      />

      <PhotoGalleryDetail
        id={data?.id}
        uuid={null}
        images={data?.photoGalleryImages}
        galleryClass="slideshow detailSection detailSection--gallery"
        imageClass="slideshowPhoto"
      />

      <VideoDetail
        videoId={data?.video?.videoId}
        service={data?.video?.service}
        autoplay={false}
      />

      <VirtualTourDetail
        uuid={null}
        threeSixtiesData={data?.threeSixties}
        className="detailSection detailSection--virtualTour"
      />

    </div>
  </FadingContainer>
);

export default SubpaneMediaDetail;
