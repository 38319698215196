import React, { useEffect, useContext, useRef, useState } from 'react';

import MapContext from '../../MapPage/MapContext';
import MapControlsZoom from './MapControlsZoom/MapControlsZoom';
import MapControlsMapTypeToggle from './MapControlsMapTypeToggle/MapControlsMapTypeToggle';
import MapControlsPOIToggle from './MapControlsPOIToggle/MapControlsPOIToggle';
import MapControlsMarkersToggle from './MapControlsMarkersToggle/MapControlsMarkersToggle';
import SiteSeeTipTrigger from '../../SiteSee/SiteSeeGuides/SiteSeeTipTrigger';

import './MapControls.scss';

const MapControls = ( {
  siteSeeGuideId,
  setSiteSeeGuideId,
  setShowSiteSeeGuide,
  siteSeeGuideMode,
  setSiteSeeModalDims
} ) => {
  // Pass dims back to sitesee guide.
  const mapControlsRef = useRef( null );
  const currentMapControlsRef = mapControlsRef.current;
  useEffect( () => {
    if ( currentMapControlsRef
      && ( siteSeeGuideId === 2 || siteSeeGuideId === 'controls' )
    ) {
      setSiteSeeModalDims( currentMapControlsRef.getBoundingClientRect() );
    }
  }, [ siteSeeGuideId, currentMapControlsRef ] );

  return (
    <div className="mapControls__wrapper">

      <div className="mapControls" ref={mapControlsRef}>

        <MapControlsZoom
          siteSeeGuideId={siteSeeGuideId}
          setSiteSeeGuideId={setSiteSeeGuideId}
          siteSeeGuideMode={siteSeeGuideMode}
        />

        <MapControlsMapTypeToggle />

        <MapControlsPOIToggle />

        <MapControlsMarkersToggle />

      </div>

      <SiteSeeTipTrigger
        siteSeeGuideId={siteSeeGuideId}
        setSiteSeeGuideId={setSiteSeeGuideId}
        setShowSiteSeeGuide={setShowSiteSeeGuide}
        siteSeeGuideMode={siteSeeGuideMode}
        siteSeeTipId="controls"
      />

    </div>
  );
};

export default MapControls;
