import React, { useContext, useState, useEffect } from 'react';
import classnames from 'classnames';

import MapContext from '../../../MapPage/MapContext';

import POIIcon from './POIIcon';
import HidePOIIcon from './HidePOIIcon';

import '../MapControls.scss';

const MapControlsPOIToggle = () => {
  const [ enablePOIToggle, setEnablePOIToggle ] = useState( false );

  const {
    setPOIVisible,
    POIVisible,
    mapData,
  } = useContext( MapContext );

  const { googleMapsConfig } = mapData;

  useEffect( () => {
    if ( googleMapsConfig ) {
      const { enablePOI } = googleMapsConfig;
      if ( enablePOI ) {
        setEnablePOIToggle( true );
      }
    }
  }, [ googleMapsConfig ] );

  return enablePOIToggle && (
    <div className="mapControlToggle">
      <button
        type="button"
        className={classnames( 'mapControlToggle-toggleOption', { 'mapControlToggle-toggleOption--show': !POIVisible } )}
        onClick={() => {
          setPOIVisible( true );
        }}
      >
        <POIIcon />
      </button>

      <button
        type="button"
        className={classnames( 'mapControlToggle-toggleOption', { 'mapControlToggle-toggleOption--show': POIVisible } )}
        onClick={() => {
          setPOIVisible( false );
        }}
      >
        <HidePOIIcon />
      </button>

    </div>
  );
};

export default MapControlsPOIToggle;
