import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { preloadPhoto } from '../../utils/common';

import FadingContainer from '../FadingContainer/FadingContainer';
import { ReactComponent as DownloadIcon } from '../../SVGAssets/DownloadIcon.svg';

import './FloorPlanImagesWidget.scss';

const FloorPlanImages = ( {
  className,
  name,
  twoD,
  threeD,
  downloadablePdf,
} ) => {
  const [ activeFloorPlan, setActiveFloorPlan ] = useState( false );
  const [ imageLoaded, setImageLoaded ] = useState( false );

  if ( !twoD && !threeD && !downloadablePdf ) {
    return null;
  }

  // Preload floor plan images, so we can fade them in.
  useEffect( () => {
    const preloadFloorPlanImages = async ( firstFP ) => {
      try {
        const result = await preloadPhoto( firstFP );
        if ( result === 'Success' ) {
          setImageLoaded( true );
        }
      } catch ( err ) {
        // eslint-disable-next-line no-console
        console.error( 'Error preloading floor plan image', err );
      }
    };
    if ( twoD || threeD ) {
      const firstFP = threeD || twoD;
      if ( firstFP ) {
        preloadFloorPlanImages( firstFP );
      }
    }
  }, [ twoD, threeD ] );

  const handleFpToggle = () => {
    setActiveFloorPlan( !activeFloorPlan );
  };

  return imageLoaded && (
    <FadingContainer>
      <div id="floorplan-scroll" className={className}>

        <div className="floorPlanImagesWidget">
          <div className="floorPlanImagesWidget-images">

            { threeD && (
              <img
                className={
                  classnames(
                    'floorPlanImagesWidget-images__image',
                    { 'floorPlanImagesWidget-images__image--inactive': activeFloorPlan },
                  )
                }
                src={threeD}
                alt={`3D ${name}`}
              />
            )}

            { twoD && (
              <img
                className={
                  classnames(
                    'floorPlanImagesWidget-images__image',
                    { 'floorPlanImagesWidget-images__image--inactive': !activeFloorPlan && threeD },
                  )
                }
                src={twoD}
                alt={`2D ${name}`}
              />
            )}

          </div>

          { ( ( twoD && threeD ) || downloadablePdf ) && (
            <div className="floorPlanImagesWidget-buttons">
              { twoD && threeD && (
                <>
                  <button
                    type="button"
                    className={
                      classnames( 'floorPlanImagesWidget-buttons__button', {
                        'floorPlanImagesWidget-buttons__button--active': !activeFloorPlan,
                      } )
                    }
                    onClick={handleFpToggle}
                  >
                    3D
                  </button>
                  <button
                    type="button"
                    className={
                      classnames( 'floorPlanImagesWidget-buttons__button', {
                        'floorPlanImagesWidget-buttons__button--active': activeFloorPlan,
                      } )
                    }
                    onClick={handleFpToggle}
                  >
                    2D
                  </button>
                </>
              )}
              { downloadablePdf && (
                <button
                    type="button"
                    onClick={() => {
                      const handleDownload = () => {
                        const link = document.createElement('a');
                        link.href = downloadablePdf;
                        link.target = '_blank';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                      };
                      handleDownload();
                    }}
                    className={
                      classnames('floorPlanImagesWidget-buttons__button', 'downloadPdf')
                    }
                >
                  <DownloadIcon/>
                  <div className="phoneText">Download</div>
                  <div className="phoneText">Floor Plan</div>
                </button>
              )}
            </div>
          )}
        </div>

        <div className="floorPlanImagesWidget-disclaimer">
          Renderings are intended as a general reference.
          Features, finishes, layout, and measurements may vary.
        </div>

      </div>
    </FadingContainer>
  );
};

FloorPlanImages.propTypes = {
  className: PropTypes.string,
  threeD: PropTypes.string,
  twoD: PropTypes.string,
  name: PropTypes.string,
  downloadablePdf: PropTypes.string,
};

FloorPlanImages.defaultProps = {
  className: '',
  threeD: null,
  twoD: null,
  name: '',
  downloadablePdf: null,
};

export default FloorPlanImages;
