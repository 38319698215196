export default ( data ) => {
  const user = {
    displayName: data?.attributes?.display_name,
    mail: data?.attributes?.mail,
    fieldCrmSalesCounselorId: data?.attributes?.field_crm_sales_counselor_id,
    fieldFirstName: data?.attributes?.field_first_name,
    fieldLastName: data?.attributes?.field_last_name,
    fieldPhone: data?.attributes?.field_phone,
  };

  return user;
};
