import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import propTypes from 'prop-types';

import { assembleFloorPlanNavBtnsData } from '../FloorPlan/FloorPlanHelpers';
import { bedBathSqftArray } from '../../utils/common';

import MapContext from '../MapPage/MapContext';
import ConfigContext from '../../utils/ConfigContext/ConfigContext';
import ModalLite from '../Modal/ModalLite';
import FloorPlanDetail from '../FloorPlan/FloorPlanDetail';
import Favorite from '../Personalization/Favorite/Favorite';

import './FloorPlansPageListItem.scss';

const FloorPlansPageListItem = ( {
  data,
} ) => {
  const [ modalIsOpen, setModalIsOpen ] = useState( false );
  const [ inventory, setInventory ] = useState( 'Sold Out' );

  const {
    personalizationActive,
  } = useContext( ConfigContext );

  const { activeLevelOfCareUuid } = useContext( MapContext );

  const {
    id,
    title,
    studio,
    floorplan2d,
    floorplan3d,
    bed,
    bath,
    sqft,
    levelsOfCare,
    withDen,
    remainingInventory,
  } = data;

  // Set inventory phrase.
  // 0 is is incorrectly interpreted as false, so we have to account for that.
  useEffect( () => {
    if (remainingInventory && remainingInventory <= 3 ) {
      setInventory( `${remainingInventory} Left` );
    }
  }, [ remainingInventory ] );

  // Image for floor plan tile.
  const FloorPlansItemCoverImage = () => {
    let floorPlansItemCoverImage = null;
    if ( floorplan3d || floorplan2d ) {
      floorPlansItemCoverImage = (
        <div
          className="imgHolder"
          style={{
            backgroundImage: `url(${
              floorplan3d?.oneThirdScreen || floorplan2d?.oneThirdScreen
            })`,
          }}
        />
      );
    } else {
      floorPlansItemCoverImage = (
        <div className="imgHolder imgHolder--noImage">
          No Floor Plan Image Available
        </div>
      );
    }
    return floorPlansItemCoverImage;
  };

  const bedBathSqft = bedBathSqftArray( studio, bed, bath, sqft, withDen );

  const modalTitle = {
    title,
    tags: [],
    bedBathSqft,
  };

  // Add inventory tag.
  if ( remainingInventory <= 3 ) {
    modalTitle.tags.push(
      {
        type: 'soldOut',
        text: inventory,
      },
    );
  }

  const navBtns = assembleFloorPlanNavBtnsData( data );

  const openModal = useCallback( () => {
    setModalIsOpen( true );
  }, [] );

  return activeLevelOfCareUuid === levelsOfCare && (
    <>
      <ModalLite
        isOpen={modalIsOpen}
        handleModalHide={() => setModalIsOpen( false )}
        setModalIsOpen={setModalIsOpen}
        showBlur
        modalLiteHeaderData={{
          title: modalTitle,
          navBtns,
        }}
      >

        <FloorPlanDetail
          data={data}
          hasInternalHeader={false}
          navBtns={null}
          navClass=""
        />

      </ModalLite>

      <div className="gridItem">
        <button
          className="modalOpen gridItemTrigger"
          onClick={openModal}
          type="button"
        >
          <div className="imgContainer">
            <FloorPlansItemCoverImage />

            { remainingInventory <= 3 && (
              <div className="gridItemBanner">
                <span className="gridItemBanner__text">{ inventory }</span>
              </div>
            )}

          </div>
        </button>

        { personalizationActive && (
          <Favorite
            type="floor_plan"
            uuid={id}
            hasShadow={false}
          />
        )}

        <div className="floorplanInfo">
          <h2 className="type-display-xl">{title}</h2>

          { bedBathSqft?.length > 0 && (
            <h3 className="floorplanInfo__subtitle">
              { bedBathSqft.map( ( item, i ) => (
                <span
                  key={i}
                  className="floorplanInfo__subtitle__group"
                >
                  {item}
                </span>
              ) )}
            </h3>
          )}

        </div>

      </div>
    </>
  );
};

FloorPlansPageListItem.propTypes = {
  data: propTypes.shape( {
    id: propTypes.string,
    title: propTypes.string,
    studio: propTypes.bool,
    floorplan2d: propTypes.shape( {
      oneThirdScreen: propTypes.string,
    } ),
    floorplan3d: propTypes.shape( {
      oneThirdScreen: propTypes.string,
    } ),
    bed: propTypes.number,
    bath: propTypes.number,
    sqft: propTypes.number,
    levelsOfCare: propTypes.string,
    withDen: propTypes.string,
    remainingInventory: propTypes.number,
  } ),
  showSoldOut: propTypes.bool,
};

FloorPlansPageListItem.defaultProps = {
  data: {},
  showSoldOut: false,
};

export default FloorPlansPageListItem;
